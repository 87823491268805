import { SUBJECT_MASTER_ROUTE } from "config/route-consts";
import { ProtectedRoute } from "pages/Authentication/ProtectedRoute";
import { Switch } from "react-router-dom";
import SubjectMaster from "./SubjectMaster/SubjectMaster";

function SubjectView() {
  return (
    <Switch>
      <ProtectedRoute
        path={SUBJECT_MASTER_ROUTE}
        key={SUBJECT_MASTER_ROUTE}
        component={SubjectMaster}
        auth={true}
      />
    </Switch>
  );
}

export { SubjectMaster };
export default SubjectView;
