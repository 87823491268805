/* begin general import */

import {
  Add16,
  OverflowMenuHorizontal24,
  SettingsAdjust16,
  TrashCan16,
  Upload16,
  Websheet16,
  Download16,
} from "@carbon/icons-react";
import { Dropdown, Menu, PaginationProps, Spin, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import PageHeader from "components/PageHeader/PageHeader";
import { MAJOR_ROUTE } from "config/route-consts";
import { Major, MajorFilter } from "models/Major";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionBarComponent,
  AdvanceIdFilterMaster,
  Button,
  OneLineText,
  Pagination,
  StandardTable,
  StatusLine,
  TagFilter,
} from "react3l-ui-library";
import { filterService } from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { masterService } from "services/page-services/master-service";
import { queryStringService } from "services/page-services/query-string-service";
import {
  getAntOrderType,
  tableService,
} from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import MajorAdvanceFilter from "./MajorAdvanceFilter";
import { Status, StatusFilter } from "models/Status";
import { IdFilter } from "react3l-advanced-filters";
import { majorRepository } from "repositories/major-repository";
import { renderMasterIndex } from "helpers/table";
import { importExportService } from "services/page-services/import-export-service";
import { Organization, OrganizationFilter } from "models/Organization";
import InputSearch from "components/InputSearch";
import LayoutHeader from "components/LayoutHeader";
import LayoutCell from "components/LayoutCell";
import { formatNumber } from "helpers/number";
import TruncateCell from "components/TruncateCell";
/* end individual import */

function MajorMaster() {
  const [translate] = useTranslation();
  const [modelFilter, dispatch] = queryStringService.useQueryString(
    MajorFilter,
    { skip: 0, take: 10 }
  );

  const {
    value: filter,
    handleChangeAllFilter,
    handleChangeSelectFilter,
    handleChangeInputSearch,
  } = filterService.useFilter(modelFilter, dispatch);

  const { list, count, loadingList, handleResetList, handleLoadList } =
    listService.useList(
      majorRepository.list,
      majorRepository.count,
      filter,
      dispatch
    );

  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeAllFilter
  );

  const {
    handleAction,
    handleBulkAction,
    canBulkAction,
    selectedRowKeys,
    rowSelection,
    setSelectedRowKeys,
  } = listService.useRowSelection(
    majorRepository.delete,
    majorRepository.bulkDelete,
    null,
    null,
    null,
    handleResetList
  );

  const { handleDeleteItem, handleGoDetail, handleGoCreate } =
    masterService.useMasterAction(MAJOR_ROUTE, handleAction);

  const { handleListExport, handleExportTemplateList } =
    importExportService.useExport();

  const {
    loading: loadingImport,
    handleImportList,
    ref: importButtonRef,
    handleClick,
  } = importExportService.useImport(handleResetList);

  const {
    loading: loadingImportMajorSubjectMappings,
    handleImportList: handleImportMajorSubjectMappings,
    ref: importButtonMajorSubjectMappingsRef,
    handleClick: handleClickMajorSubjectMappings,
  } = importExportService.useImport(handleResetList);

  const [visible, setVisible] = React.useState<boolean>(false);

  const menuAction = React.useCallback(
    (id: number, major: Major) => (
      <Menu>
        <Menu.Item key="1">
          <div
            className="ant-action-menu text-center"
            onClick={handleGoDetail(id)}
          >
            {translate("general.actions.edit")}
          </div>
        </Menu.Item>
        {!major?.used && (
          <Menu.Item key="2">
            <div
              className="ant-action-menu text-center"
              onClick={handleDeleteItem(major)}
            >
              {translate("general.actions.delete")}
            </div>
          </Menu.Item>
        )}
      </Menu>
    ),
    [handleGoDetail, translate, handleDeleteItem]
  );

  const pagination: PaginationProps = tableService.usePagination(filter, count);

  const columns: ColumnProps<Major>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
          />
        ),
        key: "index",
        width: 60,
        render(...params: [number, Major, number]) {
          const index = renderMasterIndex<Major>(pagination)(...params);
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={formatNumber(index)} />
            </LayoutCell>
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "code"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("majors.code")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].code),
        dataIndex: nameof(list[0].code),
        sorter: true,
        sortOrder: getAntOrderType<Major, MajorFilter>(
          filter,
          nameof(list[0].code)
        ),
        width: "25%",
        render(...params: [string, Major, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "name"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("majors.name")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].name),
        dataIndex: nameof(list[0].name),
        sorter: true,
        sortOrder: getAntOrderType<Major, MajorFilter>(
          filter,
          nameof(list[0].name)
        ),
        render(...params: [string, Major, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "organization"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("majors.organization")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].organization),
        dataIndex: nameof(list[0].organization),
        sorter: true,
        sortOrder: getAntOrderType<Major, MajorFilter>(
          filter,
          nameof(list[0].organization)
        ),
        width: "25%",
        render(...params: [Organization, Major, number]) {
          return <TruncateCell renderContent={params[0]?.name} />;
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "status"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("majors.status")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].status),
        dataIndex: nameof(list[0].status),
        sorter: true,
        sortOrder: getAntOrderType<Major, MajorFilter>(
          filter,
          nameof(list[0].status)
        ),
        width: 150,
        render(...params: [Status, Major, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <StatusLine value={params[0]?.name} color={params[0]?.color} />
            </LayoutCell>
          );
        },
      },

      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("general.actions.label")}
            />
          );
        },
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 80,
        align: "center",
        render(id: number, major: Major) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id, major)}
                trigger={["click"]}
                placement="bottom"
                arrow
              >
                <OverflowMenuHorizontal24 />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [translate, pagination, list, filter, menuAction]
  );

  const handleCancel = React.useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <>
      <Spin
        spinning={
          loadingImport || loadingImportMajorSubjectMappings || loadingList
        }
      >
        <div className="page-content">
          <PageHeader
            title={translate("majors.master.subHeader")}
            breadcrumbItems={[
              translate("majors.master.header"),
              translate("majors.master.subHeader"),
            ]}
          />

          <div className="page page-master m-l--sm m-r--xxl m-b--xxs">
            <div className="page-master__title p-l--sm p-t--xs p-b--xs">
              {translate("majors.master.subHeader")}
            </div>
            <div className="page-master__content">
              <div className="page-master__tag-filter">
                <TagFilter
                  value={filter}
                  translate={translate}
                  keyTranslate={"majors"}
                  handleChangeFilter={handleChangeAllFilter}
                  onClear={() => {
                    return 0;
                  }}
                />
              </div>
              {(!selectedRowKeys || selectedRowKeys?.length === 0) && (
                <div className="page-master__filter-wrapper d-flex align-items-center justify-content-between">
                  <div className="page-master__filter d-flex align-items-center justify-content-start">
                    <div className="d-flex align-items-center">
                      <div className="">
                        <AdvanceIdFilterMaster
                          value={
                            filter[nameof(list[0].organizationId)]["equal"]
                          }
                          placeHolder={translate(
                            "majors.placeholder.organization"
                          )}
                          classFilter={OrganizationFilter}
                          onChange={handleChangeSelectFilter({
                            fieldName: nameof(list[0].organization),
                            fieldType: "equal",
                            classFilter: IdFilter,
                          })}
                          getList={majorRepository.filterListOrganization}
                          title={translate("majors.organization")}
                        />
                      </div>
                      <div className="">
                        <AdvanceIdFilterMaster
                          value={filter[nameof(list[0].statusId)]["equal"]}
                          placeHolder={translate("majors.placeholder.status")}
                          classFilter={StatusFilter}
                          onChange={handleChangeSelectFilter({
                            fieldName: nameof(list[0].status),
                            fieldType: "equal",
                            classFilter: IdFilter,
                          })}
                          getList={majorRepository.filterListStatus}
                          title={translate("majors.status")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="page-master__filter-action-search d-flex align-items-center">
                    <Button
                      type="icon-only-ghost"
                      icon={<SettingsAdjust16 />}
                      onClick={() => setVisible(true)}
                      className="btn--xl"
                    />
                    <InputSearch
                      value={filter?.search}
                      classFilter={MajorFilter}
                      placeHolder={translate("general.placeholder.search")}
                      onChange={handleChangeInputSearch}
                    />
                  </div>

                  <div className="page-master__actions  d-flex align-items-center justify-content-start">
                    <div className="page-master__filter-action d-flex align-items-center">
                      <input
                        ref={importButtonRef}
                        type="file"
                        style={{ display: "none" }}
                        id="master-import"
                        onClick={handleClick}
                        onChange={handleImportList(majorRepository.import)}
                      />

                      <Tooltip title={"Xuất excel Danh sách ngành học"}>
                        <div>
                          <Button
                            type="icon-only-ghost"
                            icon={<Download16 />}
                            onClick={handleListExport(
                              filter,
                              majorRepository.export
                            )}
                            className="btn--xl btn-grey ml-3"
                          />
                        </div>
                      </Tooltip>

                      <Tooltip title={translate("majors.actions.import")}>
                        <div>
                          <Button
                            type="icon-only-ghost"
                            icon={<Upload16 />}
                            onClick={() => {
                              importButtonRef.current.click();
                            }}
                            className="btn--xl btn-grey ml-3"
                          />
                        </div>
                      </Tooltip>

                      <Tooltip
                        title={translate("majors.actions.exportTemplate")}
                      >
                        <div>
                          <Button
                            type="icon-only-ghost"
                            icon={<Websheet16 />}
                            onClick={handleExportTemplateList(
                              majorRepository.exportTemplate
                            )}
                            className="btn--xl btn-grey ml-3"
                          />
                        </div>
                      </Tooltip>

                      <input
                        ref={importButtonMajorSubjectMappingsRef}
                        type="file"
                        style={{ display: "none" }}
                        id="master-import"
                        onClick={handleClickMajorSubjectMappings}
                        onChange={handleImportMajorSubjectMappings(
                          majorRepository.importMapping
                        )}
                      />
                      <Tooltip title="Nhập excel Danh sách môn học theo ngành học">
                        <div>
                          <Button
                            type="icon-only-outline-primary"
                            icon={<Upload16 />}
                            onClick={() => {
                              importButtonMajorSubjectMappingsRef.current.click();
                            }}
                            className="btn--xl btn-grey ml-3"
                          />
                        </div>
                      </Tooltip>
                      <Tooltip title="Xuất tệp mẫu Danh sách môn học theo ngành học">
                        <div>
                          <Button
                            type="icon-only-outline-primary"
                            icon={<Websheet16 />}
                            onClick={handleExportTemplateList(
                              majorRepository.exportTemplateMapping
                            )}
                            className="btn--xl btn-grey ml-3"
                          />
                        </div>
                      </Tooltip>
                      <Button
                        type="primary"
                        className="btn--lg"
                        icon={<Add16 />}
                        onClick={handleGoCreate}
                      >
                        {translate("general.actions.create")}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="page-master__content-table">
              <ActionBarComponent
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
              >
                <Button
                  icon={<TrashCan16 />}
                  type="ghost-primary"
                  className="btn--lg"
                  disabled={!canBulkAction}
                  onClick={() => handleBulkAction(selectedRowKeys)}
                >
                  {translate("general.actions.delete")}
                </Button>
              </ActionBarComponent>
              <StandardTable
                rowKey={nameof(list[0].id)}
                columns={columns}
                dataSource={list}
                isDragable={true}
                tableSize={"md"}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                spinning={loadingList}
                bordered
                scroll={{ y: 500 }}
              />

              <Pagination
                skip={filter?.skip}
                take={filter?.take}
                total={count}
                onChange={handlePagination}
                //canChangePageSize={false}
              />
            </div>
          </div>
        </div>
      </Spin>

      {visible && (
        <MajorAdvanceFilter
          visible={visible}
          handleClose={handleCancel}
          visibleFooter={true}
          loading={false}
          size={"sm"}
          filter={filter}
          setVisible={setVisible}
          handleChangeAllFilter={handleChangeAllFilter}
        />
      )}
    </>
  );
}
export default MajorMaster;
