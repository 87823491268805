import TrashCan16 from "@carbon/icons-react/es/trash-can/16";
import { ColumnsType } from "antd/lib/table";
import LayoutCell from "components/LayoutCell";
import LayoutHeader from "components/LayoutHeader";
import TimePicker from "components/TimePicker";
import { convertTimeToDecimal } from "helpers/date-time";
import { formatNumber } from "helpers/number";
import { renderMasterIndex } from "helpers/table";
import { ClassShift, ClassShiftFilter } from "models/ClassShift";
import { CourseSection } from "models/CourseSection";
import {
  CourseSectionShift,
  CourseSectionShiftFilter,
} from "models/CourseSectionShift";
import { Weekday, WeekdayFilter } from "models/Weekday";
import moment, { Moment } from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormItem, InputText, OneLineText, Select } from "react3l-ui-library";
import { courseSectionRepository } from "repositories/course-section-repository";
import { utilService } from "services/common-services/util-service";
import { ModelAction } from "services/page-services/detail-service";
import { fieldService } from "services/page-services/field-service";
import {
  FilterAction,
  filterReducer,
} from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { tableService } from "services/page-services/table-service";
import nameof from "ts-nameof.macro";

export default function useCourseSectionShift(
  model: CourseSection,
  dispatch: React.Dispatch<ModelAction<CourseSection>>
) {
  const [translate] = useTranslation();
  const [filter, dispatchFilter] = React.useReducer<
    React.Reducer<
      CourseSectionShiftFilter,
      FilterAction<CourseSectionShiftFilter>
    >
  >(filterReducer, new CourseSectionShiftFilter());

  const { list } = listService.useLocalList(model?.courseSectionShifts, filter);

  const { handleChangeListField } = fieldService.useField(model, dispatch);

  const { handleDeleteRow, handleChangeCell } = tableService.useContentTable(
    CourseSectionShiftFilter,
    CourseSectionShift,
    list,
    handleChangeListField({
      fieldName: "courseSectionShifts",
    }),
    dispatchFilter
  );

  const handleAddCourseSectionShift = React.useCallback(() => {
    list.push({
      ...new CourseSectionShift(),
      startedHour: 0,
      endedHour: 0,
    });
    handleChangeListField({
      fieldName: "courseSectionShifts",
    })(list);
  }, [list, handleChangeListField]);

  const courseSectionShiftColumns: ColumnsType<CourseSection> = React.useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
            position="center"
          />
        ),
        key: "index",
        width: 60,
        render(...params: [number, CourseSection, number]) {
          const index = renderMasterIndex<CourseSection>()(...params);
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={formatNumber(index)} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("courseSections.courseSectionShifts.weekday")}
            isSorter={false}
          />
        ),
        key: nameof(list[0].weekday),
        dataIndex: nameof(list[0].weekday),
        render(...params: [Weekday, CourseSectionShift, number]) {
          return (
            <div className="m-b--xxs m-r--sm m-l--xxs ">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  nameof(params[1].weekday)
                )}
                message={params[1].errors?.weekday}
              >
                <Select
                  type={0}
                  classFilter={WeekdayFilter}
                  placeHolder={translate(
                    "courseSections.courseSectionShifts.placeholder.weekday"
                  )}
                  getList={courseSectionRepository.singleListWeekday}
                  onChange={(id, T) => {
                    handleChangeCell(params[1]?.key, "weekdayId", id);
                    handleChangeCell(params[1]?.key, "weekday", T);
                  }}
                  value={params[0]}
                  appendToBody
                />
              </FormItem>
            </div>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("courseSections.courseSectionShifts.classShift")}
            isSorter={false}
          />
        ),
        key: nameof(list[0].classShift),
        dataIndex: nameof(list[0].classShift),
        render(...params: [ClassShift, CourseSectionShift, number]) {
          return (
            <div className="m-b--xxs m-r--sm m-l--xxs ">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  nameof(params[1].classShift)
                )}
                message={params[1].errors?.classShift}
              >
                <Select
                  type={0}
                  classFilter={ClassShiftFilter}
                  placeHolder={translate(
                    "courseSections.courseSectionShifts.placeholder.classShift"
                  )}
                  getList={courseSectionRepository.singleListClassShift}
                  onChange={(id, T) => {
                    handleChangeCell(params[1]?.key, "classShiftId", id);
                    handleChangeCell(params[1]?.key, "classShift", T);
                  }}
                  value={params[0]}
                  appendToBody
                />
              </FormItem>
            </div>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("courseSections.courseSectionShifts.startedHour")}
          />
        ),
        key: nameof(list[0].startedHour),
        dataIndex: nameof(list[0].startedHour),
        render(...params: [number, CourseSectionShift, number]) {
          const hours = Math.floor(params[0]);
          const minutes = Math.round((params[0] - hours) * 60);
          return (
            <div className="m-b--xxs m-r--sm m-l--xxs ">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  nameof(params[1].startedHour)
                )}
                message={params[1].errors?.startedHour}
              >
                <TimePicker
                  type={0}
                  value={moment().startOf("day").hour(hours).minute(minutes)}
                  onChange={(value: Moment, timeString: string) =>
                    handleChangeCell(
                      params[1]?.key,
                      "startedHour",
                      convertTimeToDecimal(timeString)
                    )
                  }
                  timeFormat={"HH:mm"}
                  placeholder={translate(
                    "courseSections.courseSectionShifts.placeholder.startedHour"
                  )}
                />
              </FormItem>
            </div>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("courseSections.courseSectionShifts.endedHour")}
          />
        ),
        key: nameof(list[0].endedHour),
        dataIndex: nameof(list[0].endedHour),
        render(...params: [number, CourseSectionShift, number]) {
          const hours = Math.floor(params[0]);
          const minutes = Math.round((params[0] - hours) * 60);

          return (
            <div className="m-b--xxs m-r--sm m-l--xxs ">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  nameof(params[1].endedHour)
                )}
                message={params[1].errors?.endedHour}
              >
                <TimePicker
                  type={0}
                  value={moment().startOf("day").hour(hours).minute(minutes)}
                  onChange={(value: Moment, timeString: string) =>
                    handleChangeCell(
                      params[1]?.key,
                      "endedHour",
                      convertTimeToDecimal(timeString)
                    )
                  }
                  timeFormat={"HH:mm"}
                  placeholder={translate(
                    "courseSections.courseSectionShifts.placeholder.endedHour"
                  )}
                />
              </FormItem>
            </div>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("courseSections.courseSectionShifts.room")}
          />
        ),
        key: nameof(list[0].room),
        dataIndex: nameof(list[0].room),
        render(...params: [string, CourseSectionShift, number]) {
          return (
            <div className="m-b--xxs m-r--sm m-l--xxs ">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  nameof(params[1].room)
                )}
                message={params[1].errors?.room}
              >
                <InputText
                  type={0}
                  value={params[0]}
                  placeHolder={translate(
                    "courseSections.courseSectionShifts.placeholder.room"
                  )}
                  className={"tio-account_square_outlined"}
                  onChange={(value) =>
                    handleChangeCell(params[1]?.key, "room", value)
                  }
                />
              </FormItem>
            </div>
          );
        },
      },

      {
        title: translate("general.actions.label"),
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 80,
        align: "center",
        render(...params: [any, CourseSectionShift, number]) {
          return (
            <div className="text-center" style={{ cursor: "pointer" }}>
              <TrashCan16
                color="#da1e28"
                onClick={() => handleDeleteRow(params[1]?.key)}
              />
            </div>
          );
        },
      },
    ],
    [handleChangeCell, handleDeleteRow, list, translate]
  );

  return {
    courseSectionShiftList: list,
    handleAddCourseSectionShift,
    courseSectionShiftColumns,
    filter,
  };
}
