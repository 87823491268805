import { Col, PaginationProps, Row } from "antd";
import { ColumnProps } from "antd/lib/table";
import { Major } from "models/Major";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  InputText,
  Modal,
  OneLineText,
  Pagination,
  StandardTable,
} from "react3l-ui-library";
import nameof from "ts-nameof.macro";
import { Subject, SubjectFilter } from "models/Subject";
import { TableRowSelection } from "antd/lib/table/interface";
import { renderMasterIndex } from "helpers/table";
import { tableService } from "services/page-services/table-service";
import LayoutHeader from "components/LayoutHeader";
import LayoutCell from "components/LayoutCell";
import { formatNumber } from "helpers/number";
import TruncateCell from "components/TruncateCell";

interface MajorContentModalProps {
  visible: boolean;
  listSubject?: Subject[];
  countSubject?: number;
  loadList?: boolean;
  subjectFilter?: SubjectFilter;
  rowSelection?: TableRowSelection<any>;
  handleClose: () => void;
  handleSave: (data: Major) => void;
  handleChangeInputFilter?: (value?: any) => void;
  handleTableChange?: (value: any) => void;
  handlePagination?: (skip: number, take: number) => void;
  handleChangerRowSelection: (record: any) => void;
}
export function MajorContentModal(props: MajorContentModalProps) {
  const {
    rowSelection,
    visible,
    subjectFilter,
    listSubject,
    countSubject,
    loadList,
    handleSave,
    handleClose,
    handleChangeInputFilter,
    handleTableChange,
    handlePagination,
    handleChangerRowSelection,
  } = props;

  const [translate] = useTranslation();

  const pagination: PaginationProps = tableService.usePagination(
    subjectFilter,
    countSubject
  );

  const columns: ColumnProps<Subject>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
            position="center"
          />
        ),
        key: "index",
        width: 60,
        render(...params: [number, Subject, number]) {
          const index = renderMasterIndex<Subject>(pagination)(...params);
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={formatNumber(index)} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("majors.contents.code")}
          />
        ),
        key: "code",
        dataIndex: "code",
        width: "50%",
        render(...params: [string, Subject, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },

      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("majors.contents.name")}
          />
        ),
        key: "name",
        dataIndex: "name",
        render(...params: [string, Subject, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },
    ],
    [pagination, translate]
  );

  return (
    <Modal
      size={1024}
      visibleFooter={true}
      visible={visible}
      title={translate("majors.contents.title")}
      handleSave={handleSave}
      handleCancel={handleClose}
      titleButtonCancel={translate("general.actions.close")}
      titleButtonApply={translate("general.actions.save")}
    >
      <div className="modal-content">
        <Row>
          <Col lg={12} className="m-b--xxs p-r--sm">
            <InputText
              value={subjectFilter?.search}
              placeHolder={translate("majors.contents.placeholder.search")}
              onChange={handleChangeInputFilter}
              type={0}
            />
          </Col>
        </Row>
        <div className=" m-t--sm">
          <StandardTable
            rowKey={nameof(listSubject[0].id)}
            columns={columns}
            dataSource={listSubject}
            isDragable={true}
            scroll={{ y: 500 }}
            tableSize={"md"}
            onChange={handleTableChange}
            loading={loadList}
            rowSelection={rowSelection}
            bordered
            onRow={(record) => {
              return {
                onClick: (_event) => handleChangerRowSelection(record), // click row
              };
            }}
          />
          <Pagination
            skip={subjectFilter?.skip}
            take={subjectFilter?.take}
            total={countSubject}
            onChange={handlePagination}
          />
        </div>
      </div>
    </Modal>
  );
}
