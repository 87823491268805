import {
  COURSE_SECTION_ROLL_CALL_DETAIL_ROUTE,
  ROLL_CALL_DETAIL_ROUTE,
  ROLL_CALL_MASTER_ROUTE,
} from "config/route-consts";
import { ProtectedRoute } from "pages/Authentication/ProtectedRoute";
import { Switch } from "react-router-dom";
import RollCallMaster from "./RollCallMaster/RollCallMaster";
import RollCallDetail from "./RollCallDetail/RollCallDetail";
import CourseSectionRollCallDetail from "./RollCallDetail/RollCallDetailHook/CourseSectionRollCallDetail";

function RollCallView() {
  return (
    <Switch>
      <ProtectedRoute
        path={ROLL_CALL_MASTER_ROUTE}
        key={ROLL_CALL_MASTER_ROUTE}
        component={RollCallMaster}
        auth={true}
      />
      <ProtectedRoute
        path={ROLL_CALL_DETAIL_ROUTE}
        key={ROLL_CALL_DETAIL_ROUTE}
        component={RollCallDetail}
        auth={true}
      />
      <ProtectedRoute
        path={COURSE_SECTION_ROLL_CALL_DETAIL_ROUTE}
        key={COURSE_SECTION_ROLL_CALL_DETAIL_ROUTE}
        component={CourseSectionRollCallDetail}
        auth={true}
      />
    </Switch>
  );
}

export { RollCallMaster, RollCallDetail };
export default RollCallView;
