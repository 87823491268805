/* begin general import */
import {
  Add16,
  OverflowMenuHorizontal24,
  SettingsAdjust16,
  TrashCan16,
  Upload16,
  Websheet16,
  Download16,
} from "@carbon/icons-react";
import { Dropdown, Menu, PaginationProps, Spin } from "antd";
import { ColumnProps } from "antd/lib/table";
import PageHeader from "components/PageHeader/PageHeader";
import { SUBJECT_ROUTE } from "config/route-consts";
import { Subject, SubjectFilter } from "models/Subject";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionBarComponent,
  AdvanceIdFilterMaster,
  Button,
  OneLineText,
  Pagination,
  StandardTable,
  StatusLine,
  TagFilter,
} from "react3l-ui-library";
import { detailService } from "services/page-services/detail-service";
import { filterService } from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { masterService } from "services/page-services/master-service";
import { queryStringService } from "services/page-services/query-string-service";
import {
  getAntOrderType,
  tableService,
} from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import SubjectDetailDrawer from "../SubjectDetail/SubjectDetailDrawer";
import SubjectAdvanceFilter from "./SubjectAdvanceFilter";
import { Status, StatusFilter } from "models/Status";
import { IdFilter } from "react3l-advanced-filters";
import { subjectRepository } from "repositories/subject-repository";
import { renderMasterIndex } from "helpers/table";
import { importExportService } from "services/page-services/import-export-service";
import InputSearch from "components/InputSearch";
import LayoutHeader from "components/LayoutHeader";
import LayoutCell from "components/LayoutCell";
import { formatNumber } from "helpers/number";
import TruncateCell from "components/TruncateCell";
import { Major, MajorFilter } from "models/Major";
/* end individual import */

function SubjectMaster() {
  const [translate] = useTranslation();
  const [modelFilter, dispatch] = queryStringService.useQueryString(
    SubjectFilter,
    { skip: 0, take: 10 }
  );

  const {
    value: filter,
    handleChangeAllFilter,
    handleChangeSelectFilter,
    handleChangeInputSearch,
  } = filterService.useFilter(modelFilter, dispatch);

  const { list, count, loadingList, handleResetList, handleLoadList } =
    listService.useList(
      subjectRepository.list,
      subjectRepository.count,
      filter,
      dispatch
    );

  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeAllFilter
  );

  const {
    handleAction,
    handleBulkAction,
    canBulkAction,
    selectedRowKeys,
    rowSelection,
    setSelectedRowKeys,
  } = listService.useRowSelection(
    subjectRepository.delete,
    subjectRepository.bulkDelete,
    null,
    null,
    null,
    handleResetList
  );

  const { handleDeleteItem } = masterService.useMasterAction(
    SUBJECT_ROUTE,
    handleAction
  );

  const {
    model,
    dispatch: dispatchModal,
    isOpenDetailModal,
    handleOpenDetailModal,
    handleCloseDetailModal,
    handleSaveModel,
    loadingModel,
    handleChangeSingleField,
  } = detailService.useDetailModal(
    Subject,
    subjectRepository.get,
    subjectRepository.save,
    handleLoadList
  );

  const { handleListExport, handleExportTemplateList } =
    importExportService.useExport();

  const {
    loading: loadingImport,
    handleImportList,
    ref: importButtonRef,
    handleClick,
  } = importExportService.useImport(handleResetList);

  const [visible, setVisible] = React.useState<boolean>(false);

  const menuAction = React.useCallback(
    (id: number, subject: Subject) => (
      <Menu>
        {
          <Menu.Item key="1">
            <div
              className="ant-action-menu text-center"
              onClick={() => handleOpenDetailModal(id)}
            >
              {translate("general.actions.edit")}
            </div>
          </Menu.Item>
        }

        {!subject?.used && (
          <Menu.Item key="2">
            <div
              className="ant-action-menu text-center"
              onClick={handleDeleteItem(subject)}
            >
              {translate("general.actions.delete")}
            </div>
          </Menu.Item>
        )}
      </Menu>
    ),
    [translate, handleDeleteItem, handleOpenDetailModal]
  );

  const pagination: PaginationProps = tableService.usePagination(filter, count);

  const columns: ColumnProps<Subject>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
            position="center"
          />
        ),
        key: "index",
        width: 60,
        align: "center",
        render(...params: [number, Subject, number]) {
          const index = renderMasterIndex<Subject>(pagination)(...params);
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={formatNumber(index)} />
            </LayoutCell>
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "code"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("subjects.code")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].code),
        dataIndex: nameof(list[0].code),
        sorter: true,
        sortOrder: getAntOrderType<Subject, SubjectFilter>(
          filter,
          nameof(list[0].code)
        ),
        width: "20%",
        render(...params: [string, Subject, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "name"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("subjects.name")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        width: "25%",
        key: nameof(list[0].name),
        dataIndex: nameof(list[0].name),
        sorter: true,
        sortOrder: getAntOrderType<Subject, SubjectFilter>(
          filter,
          nameof(list[0].name)
        ),
        render(...params: [string, Subject, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },
      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("subjects.majorSubjectMappings")}
            />
          );
        },
        key: nameof(list[0].majorSubjectMappings),
        dataIndex: nameof(list[0].majorSubjectMappings),
        render(...params: [Major[], Subject, number]) {
          const listMajorSubjectName = params[0]
            ? params[0]?.map((major: Major) => major?.major?.name)
            : [];
          return (
            <TruncateCell renderContent={listMajorSubjectName.join(", ")} />
          );
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "status"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("subjects.status")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].status),
        dataIndex: nameof(list[0].status),
        sorter: true,
        sortOrder: getAntOrderType<Subject, SubjectFilter>(
          filter,
          nameof(list[0].status)
        ),
        width: 200,
        render(...params: [Status, Subject, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <StatusLine value={params[0]?.name} color={params[0]?.color} />
            </LayoutCell>
          );
        },
      },

      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("general.actions.label")}
            />
          );
        },
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 80,
        align: "center",
        render(id: number, subject: Subject) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id, subject)}
                trigger={["click"]}
                placement="bottom"
                arrow
              >
                <OverflowMenuHorizontal24 />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [translate, pagination, list, filter, menuAction]
  );

  const handleCancel = React.useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <>
      <Spin spinning={loadingImport || loadingList}>
        <div className="page-content">
          <PageHeader
            title={translate("subjects.master.subHeader")}
            breadcrumbItems={[
              translate("subjects.master.header"),
              translate("subjects.master.subHeader"),
            ]}
          />

          <div className="page page-master m-l--sm m-r--xxl m-b--xxs">
            <div className="page-master__title p-l--sm p-t--xs p-b--xs">
              {translate("subjects.master.subHeader")}
            </div>
            <div className="page-master__content">
              <div className="page-master__tag-filter">
                <TagFilter
                  value={filter}
                  translate={translate}
                  keyTranslate={"subjects"}
                  handleChangeFilter={handleChangeAllFilter}
                  onClear={() => {
                    return 0;
                  }}
                />
              </div>
              {(!selectedRowKeys || selectedRowKeys?.length === 0) && (
                <div className="page-master__filter-wrapper d-flex align-items-center justify-content-between">
                  <div className="page-master__filter d-flex align-items-center justify-content-start">
                    <div className="d-flex align-items-center">
                      <div className="">
                        <AdvanceIdFilterMaster
                          value={filter["majorId"]["equal"]}
                          placeHolder={translate("subjects.placeholder.major")}
                          classFilter={MajorFilter}
                          onChange={handleChangeSelectFilter({
                            fieldName: "major",
                            fieldType: "equal",
                            classFilter: IdFilter,
                          })}
                          getList={subjectRepository.filterListMajor}
                          title={translate("subjects.major")}
                        />
                      </div>
                      <div className="">
                        <AdvanceIdFilterMaster
                          value={filter[nameof(list[0].statusId)]["equal"]}
                          placeHolder={translate("subjects.placeholder.status")}
                          classFilter={StatusFilter}
                          onChange={handleChangeSelectFilter({
                            fieldName: nameof(list[0].status),
                            fieldType: "equal",
                            classFilter: IdFilter,
                          })}
                          getList={subjectRepository.filterListStatus}
                          title={translate("subjects.status")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="page-master__filter-action-search d-flex align-items-center">
                    <Button
                      type="icon-only-ghost"
                      icon={<SettingsAdjust16 />}
                      onClick={() => setVisible(true)}
                      className="btn--xl"
                    />
                    <InputSearch
                      value={filter?.search}
                      classFilter={SubjectFilter}
                      placeHolder={translate("general.placeholder.search")}
                      onChange={handleChangeInputSearch}
                    />
                  </div>

                  <div className="page-master__actions  d-flex align-items-center justify-content-start">
                    <div className="page-master__filter-action d-flex align-items-center">
                      <input
                        ref={importButtonRef}
                        type="file"
                        style={{ display: "none" }}
                        id="master-import"
                        onClick={handleClick}
                        onChange={handleImportList(subjectRepository.import)}
                      />
                      <Button
                        type="icon-only-ghost"
                        icon={<Download16 />}
                        onClick={handleListExport(
                          filter,
                          subjectRepository.export
                        )}
                        className="btn--xl btn-grey ml-3"
                      />
                      <Button
                        type="icon-only-ghost"
                        icon={<Upload16 />}
                        onClick={() => {
                          importButtonRef.current.click();
                        }}
                        className="btn--xl btn-grey ml-3"
                      />
                      <Button
                        type="icon-only-ghost"
                        icon={<Websheet16 />}
                        onClick={handleExportTemplateList(
                          subjectRepository.exportTemplate
                        )}
                        className="btn--xl btn-grey ml-3"
                      />
                      <Button
                        type="primary"
                        className="btn--lg"
                        icon={<Add16 />}
                        onClick={() => handleOpenDetailModal(null)}
                      >
                        {translate("general.actions.create")}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="page-master__content-table">
              <ActionBarComponent
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
              >
                <Button
                  icon={<TrashCan16 />}
                  type="ghost-primary"
                  className="btn--lg"
                  disabled={!canBulkAction}
                  onClick={() => handleBulkAction(selectedRowKeys)}
                >
                  {translate("general.actions.delete")}
                </Button>
              </ActionBarComponent>
              <StandardTable
                rowKey={nameof(list[0].id)}
                columns={columns}
                dataSource={list}
                isDragable={true}
                tableSize={"md"}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                spinning={loadingList}
                scroll={{ y: 500 }}
                bordered
              />

              <Pagination
                skip={filter?.skip}
                take={filter?.take}
                total={count}
                onChange={handlePagination}
                //canChangePageSize={false}
              />
            </div>
          </div>
        </div>
      </Spin>

      {visible && (
        <SubjectAdvanceFilter
          visible={visible}
          handleClose={handleCancel}
          visibleFooter={true}
          loading={false}
          size={"sm"}
          filter={filter}
          setVisible={setVisible}
          handleChangeAllFilter={handleChangeAllFilter}
        />
      )}

      <SubjectDetailDrawer
        model={model}
        visible={isOpenDetailModal}
        handleSave={handleSaveModel}
        handleCancel={handleCloseDetailModal}
        handleChangeSingleField={handleChangeSingleField}
        dispatch={dispatchModal}
        loading={loadingModel}
        visibleFooter={true}
      />
    </>
  );
}
export default SubjectMaster;
