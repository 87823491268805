import { AppUser } from "models/AppUser";
import { ClassShift } from "models/ClassShift";
import { Weekday } from "models/Weekday";
import { Model } from "react3l-common";
import { Field } from "react3l-decorators";

export class CourseSectionShift extends Model {
  @Field(Number)
  public id?: number;

  @Field(Number)
  public weekdayId?: number;

  @Field(Number)
  public courseSectionId?: number;

  @Field(Number)
  public classShiftId?: number;

  @Field(Number)
  public startedHour?: number;

  @Field(Number)
  public endedHour?: number;

  @Field(String)
  public room?: string;

  @Field(Boolean)
  public isDeleted?: boolean;

  public classShift?: ClassShift;

  public weekday?: Weekday;
}
