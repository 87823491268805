import Login from "pages/Authentication/Login/Login";
import { PrivateRoute } from "pages/Authentication/PrivateRoute";
import ForbidentView from "pages/ForbidentView/ForbidentView";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import WebFont from "webfontloader";
import "./i18n/i18n";
import * as serviceWorker from "./serviceWorker";
import store from "./store";

WebFont.load({
  google: {
    families: ["Inter"],
  },
});

const app = (
  <Fragment>
    <Provider store={store}>
      {/* <SignalRContext.Provider value={signalRService}> */}
      <React.Suspense fallback={null}>
        <BrowserRouter basename="/">
          <Switch>
            <Route exact path={`/login`} component={Login} />
            <Route exact path={`/403`} component={ForbidentView} />
            <PrivateRoute path="/" />
          </Switch>
        </BrowserRouter>
      </React.Suspense>
      {/* </SignalRContext.Provider> */}
    </Provider>
  </Fragment>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
