import { Organization } from "models/Organization";
import type { Moment } from "moment";
import { Model } from "react3l-common";
import { Field, MomentField } from "react3l-decorators";

export class AppUser extends Model {
  @Field(Number)
  public id?: number;

  @Field(Number)
  public statusId?: number;

  @Field(String)
  public username?: string;

  @Field(String)
  public password?: string;

  @Field(String)
  public otpCode?: string;

  @Field(String)
  public phone?: string;

  @MomentField()
  public otpExpired?: Moment;
  @Field(String)
  public displayName?: string;

  @Field(String)
  public singnatureUrl?: string;

  @Field(String)
  public email?: string;

  @MomentField()
  public createdAt?: Moment;

  @MomentField()
  public updatedAt?: Moment;

  @MomentField()
  public deletedAt?: Moment;
  @Field(String)
  public rowId?: string;

  @Field(Boolean)
  public used?: boolean;

  public organization?: Organization;
}
