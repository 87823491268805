import { Login16, UserAvatar16, View16, ViewOff16 } from "@carbon/icons-react";
import { Checkbox, Divider } from "antd";
import { AppUser } from "models/AppUser";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormItem } from "react3l-ui-library";
import nameof from "ts-nameof.macro";
import ChangePassword from "./ChangePassword";
import ForgotPassword from "./ForgotPassword";
import GetOtp from "./GetOtp";
import InputTextLogin from "./InputTextLogin/InputTextLogin";
import "./Login.scss";
import LoginHeader from "./LoginHeader";
import useLogin from "./LoginService";
import SuccessResultView from "./SuccessResultView";
import logo from "../../../assets/images/TVUlogo2.png";

function Login() {
  const [appUser, setAppUser] = useState<AppUser>({
    ...new AppUser(),
    username: "",
    password: "",
  });
  const [errorMessageUsername, setErrorMessageUsername] =
    useState<string>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMessagePass, setErrorMessagePass] = useState<string>(null);
  const [errorMessageOtp, setErrorMessageOtp] = useState<string>(null);
  const [errorMessageEmail, setErrorMessageEmail] =
    React.useState<string>(null);
  const [translate] = useTranslation();

  const [
    loginVisible,
    forgotPasswordVisible,
    getOtpVisible,
    changePassVisible,
    checkPass,
    confirmPass,
    showForgotPassword,
    handleChangeEmail,
    handleChangeOtp,
    handleSendOtp,
    handleSendMail,
    handleChangeNewPass,
    handleChangeConfirmPassword,
    handleChangePass,
    showLogin,
    handleLogin,
    handleChangeField,
    handleEnter,
    otp,
    email,
    newPass,
    successViewVisible,
  ] = useLogin(
    appUser,
    setAppUser,
    setErrorMessageUsername,
    setErrorMessagePass,
    setErrorMessageOtp,
    setErrorMessageEmail
  );

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = React.useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <>
      <div className="login-page">
        <LoginHeader />
        <div className="background-blur"></div>
        <div className="login-page__content d-flex align-items-center justify-content-center m-l--xxl">
          <div className="main-content-form">
            {loginVisible === true && (
              <div>
                <div className="p-b--lg">
                  <span className="logo-lg">
                    <img src={logo} alt="" height="80" />
                  </span>
                </div>
                <h2 className="login-page__content--title">
                  {translate("login.loginPage.title")}
                </h2>

                <Divider className="login-page__content--divider" />

                <div className="login-page__content--form">
                  <div className="login-page__username m-b--sm">
                    <FormItem message={errorMessageUsername}>
                      <InputTextLogin
                        inputType="text"
                        label={translate("login.loginPage.username")}
                        suffix={<UserAvatar16 />}
                        value={appUser.username}
                        onChange={handleChangeField(nameof(appUser.username))}
                        placeHolder={translate(
                          "login.loginPage.placeholder.username"
                        )}
                        action={{
                          name: translate(
                            "login.loginPage.forgetPasswordButton"
                          ),
                          action: () => showForgotPassword(),
                        }}
                        onKeyDown={handleEnter}
                      />
                    </FormItem>
                  </div>
                  <div className="login-page__password m-b--sm">
                    <FormItem message={errorMessagePass}>
                      <InputTextLogin
                        inputType={showPassword ? "text" : "password"}
                        label={translate("login.loginPage.password")}
                        suffix={
                          <button
                            type="button"
                            role="link"
                            className="btn btn-link"
                            onClick={toggleShowPassword}
                          >
                            {showPassword ? (
                              <ViewOff16 color="#044317" />
                            ) : (
                              <View16 color="#044317" />
                            )}
                          </button>
                        }
                        value={appUser.password}
                        onChange={handleChangeField(nameof(appUser.password))}
                        placeHolder={translate(
                          "login.loginPage.placeholder.password"
                        )}
                        onKeyDown={handleEnter}
                      />
                    </FormItem>
                  </div>

                  <div className="login-page__button-wrapper m-b--lg">
                    <Button
                      icon={<Login16 />}
                      className="login-button btn--lg"
                      onClick={handleLogin}
                      disabled={
                        errorMessagePass !== null ||
                        errorMessageUsername !== null
                      }
                    >
                      {translate("login.loginPage.loginButtonLabel")}
                    </Button>
                  </div>

                  <div className="remember-password pointer m-b--max">
                    <Checkbox>
                      <span className="remember-password_label">
                        {translate("login.loginPage.rememberPasswordLabel")}
                      </span>
                    </Checkbox>
                  </div>
                </div>
              </div>
            )}
            {forgotPasswordVisible && (
              <ForgotPassword
                onChangeEmail={handleChangeEmail}
                onSendMail={handleSendMail}
                showLogin={showLogin}
                errorMessageEmail={errorMessageEmail}
                email={email}
                translate={translate}
              />
            )}

            {getOtpVisible && (
              <GetOtp
                onChangeOtp={handleChangeOtp}
                onSendMail={handleSendMail}
                onSendOtp={handleSendOtp}
                otp={otp}
                showLogin={showLogin}
                errorMessageOtp={errorMessageOtp}
                translate={translate}
              />
            )}
            {changePassVisible && (
              <ChangePassword
                onChangeNewPass={handleChangeNewPass}
                onChangeConfirmPassword={handleChangeConfirmPassword}
                onChangePass={handleChangePass}
                checkPass={checkPass}
                confirmPass={confirmPass}
                showLogin={showLogin}
                newPass={newPass}
                translate={translate}
              />
            )}
            {successViewVisible && <SuccessResultView translate={translate} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
