import TrashCan16 from "@carbon/icons-react/es/trash-can/16";
import { Modal } from "antd";
import { ColumnProps } from "antd/lib/table";
import { Subject, SubjectFilter } from "models/Subject";
import { Major } from "models/Major";
import React, { ChangeEvent, RefObject } from "react";
import { useTranslation } from "react-i18next";
import { OneLineText } from "react3l-ui-library";
import {
  FilterAction,
  filterReducer,
  filterService,
} from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import nameof from "ts-nameof.macro";
import { utilService } from "services/common-services/util-service";
import { renderMasterIndex } from "helpers/table";
import _ from "lodash";
import { webService } from "services/common-services/web-service";
import { majorRepository } from "repositories/major-repository";
import { generalLanguageKeys } from "config/language-keys";
import LayoutHeader from "components/LayoutHeader";
import LayoutCell from "components/LayoutCell";
import { formatNumber } from "helpers/number";
import TruncateCell from "components/TruncateCell";

export function useMajorContentTable(
  model: Major,
  handleChangeAllField: (data: Major) => void
) {
  const [translate] = useTranslation();
  const [subscription] = webService.useSubscription();
  const [contents, setContents] = React.useState<Subject[]>([]);
  const [tableFilter, dispatchTableFilter] = React.useReducer<
    React.Reducer<SubjectFilter, FilterAction<SubjectFilter>>
  >(filterReducer, new SubjectFilter());

  const { handleChangeAllFilter: handleChangeTableFilter } =
    filterService.useFilter(tableFilter, dispatchTableFilter);

  const {
    list,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
    handleFilter,
  } = listService.useLocalListWithRowSelection(contents, tableFilter);

  React.useEffect(() => {
    if (
      model?.majorSubjectMappings &&
      model?.majorSubjectMappings?.length > 0
    ) {
      setContents(model?.majorSubjectMappings);
    } else setContents([]);
  }, [model?.majorSubjectMappings]);

  const handleDeleteSubject = React.useCallback(
    (content) => {
      const listSubject = contents.filter((item) => {
        return item.key !== content?.key;
      });
      setContents([...listSubject]);
      model.majorSubjectMappings = [...listSubject];
      handleChangeAllField(model);
      handleFilter(listSubject);
    },
    [contents, handleChangeAllField, handleFilter, model]
  );
  const handleDeleteItems = React.useCallback(() => {
    const cloneList = _.cloneDeep(list);
    const originalContent = _.cloneDeep(model?.majorSubjectMappings);
    const filteredIds = cloneList.map((item) => item.subjectId);
    const notFilteredList = originalContent.filter(
      (item) => !filteredIds.includes(item.subjectId)
    );
    const listSubject = cloneList.filter((item) => {
      return !selectedRowKeys?.includes(item?.subjectId);
    });
    const mergeList = utilService.uniqueArray(
      [...notFilteredList, ...listSubject],
      "subjectId"
    );
    handleFilter(mergeList);
    setContents([...mergeList]);
    model.majorSubjectMappings = [...mergeList];
    handleChangeAllField(model);
    setSelectedRowKeys([]);
  }, [
    handleChangeAllField,
    handleFilter,
    list,
    model,
    selectedRowKeys,
    setSelectedRowKeys,
  ]);

  const handleChangeSearchTable = React.useCallback(
    (value: string) => {
      handleChangeTableFilter({ ...tableFilter, search: value });
    },
    [handleChangeTableFilter, tableFilter]
  );

  const importButtonRef: RefObject<HTMLInputElement> =
    React.useRef<HTMLInputElement>(null);

  const handleClick = React.useCallback(() => {
    importButtonRef.current.value = null;
  }, []);

  const handleImportList = React.useCallback(() => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files.length > 0) {
        const file: File = event.target.files[0];

        subscription.add(
          majorRepository.importMapping(file).subscribe({
            next: (res: any) => {
              Modal.success({
                content: translate(generalLanguageKeys.toasts.success),
              });
            },
            error: (error) => {
              Modal.error({
                content: error.response?.data,
              });
            },
          })
        );
      }
      handleChangeSearchTable(undefined);
    };
  }, [handleChangeSearchTable, subscription, translate]);

  const subjectMajorMappingColumns: ColumnProps<Subject>[] = React.useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
            position="center"
          />
        ),
        key: "index",
        width: 60,
        render(...params: [number, Subject, number]) {
          const index = renderMasterIndex<Subject>()(...params);
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={formatNumber(index)} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("majors.contents.code")}
          />
        ),
        key: "code",
        dataIndex: "subject",
        width: "50%",
        render(...params: [Subject, Subject, number]) {
          return <TruncateCell renderContent={params[0]?.code} />;
        },
      },

      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("majors.contents.name")}
          />
        ),
        key: "name",
        dataIndex: "subject",
        render(...params: [Subject, Subject, number]) {
          return <TruncateCell renderContent={params[0]?.name} />;
        },
      },

      {
        title: translate("general.actions.label"),
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 80,
        align: "center",
        render(...params: [string, Subject, number]) {
          return (
            <div className="text-center" style={{ cursor: "pointer" }}>
              <TrashCan16
                color="#da1e28"
                onClick={() => handleDeleteSubject(params[1])}
              />
            </div>
          );
        },
      },
    ],
    [handleDeleteSubject, list, translate]
  );

  return {
    tableFilter,
    list,
    selectedRowKeys,
    rowSelection,
    subjectMajorMappingColumns,
    importButtonRef,
    handleChangeSearchTable,
    handleDeleteItems,
    handleImportList,
    handleClick,
  };
}
