import {
  COURSE_SECTION_DETAIL_ROUTE,
  COURSE_SECTION_MASTER_ROUTE,
} from "config/route-consts";
import { ProtectedRoute } from "pages/Authentication/ProtectedRoute";
import { Switch } from "react-router-dom";
import CourseSectionMaster from "./CourseSectionMaster/CourseSectionMaster";
import CourseSectionDetail from "./CourseSectionDetail/CourseSectionDetail";

function CourseSectionView() {
  return (
    <Switch>
      <ProtectedRoute
        path={COURSE_SECTION_MASTER_ROUTE}
        key={COURSE_SECTION_MASTER_ROUTE}
        component={CourseSectionMaster}
        auth={true}
      />
      <ProtectedRoute
        path={COURSE_SECTION_DETAIL_ROUTE}
        key={COURSE_SECTION_DETAIL_ROUTE}
        component={CourseSectionDetail}
        auth={true}
      />
    </Switch>
  );
}

export { CourseSectionMaster, CourseSectionDetail };
export default CourseSectionView;
