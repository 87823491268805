import { BehaviorSubject, Observable } from "rxjs";
import * as Cookie from "js-cookie";
import { LOGIN_ROUTE } from "config/route-consts";
import { profileRepository } from "repositories/profile-repository";
import { Repository } from "react3l-common";

class AuthenticationService extends Repository {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  constructor() {
    super();
    const currentUserInfo = localStorage.getItem("currentUserInfo");
    this.currentUserSubject = new BehaviorSubject<any>(
      currentUserInfo ? JSON.parse(currentUserInfo) : null
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public listPath() {
    return this.http
      .post<any>(`/api/checkin/permission/list-path`, {})
      .pipe(Repository.responseDataMapper<any>());
  }

  public logout() {
    const logoutPromise = new Promise((resolve, reject) => {
      profileRepository.logout().subscribe(
        (res: any) => {
          if (res) {
            resolve(res);
            this.currentUserSubject.next(null);
            localStorage.removeItem("currentUserInfo");
            window.location.href = LOGIN_ROUTE;
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
    return logoutPromise;
  }
}

const authenticationService = new AuthenticationService();

export default authenticationService;
