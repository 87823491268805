import { REPORT_NOT_ROLL_CALL_ROUTE } from "config/route-consts";
import { ProtectedRoute } from "pages/Authentication/ProtectedRoute";
import { Switch } from "react-router-dom";
import ReportNotRollCall from "./ReportNotRollCall";

function ReportNotRollCallView() {
  return (
    <Switch>
      <ProtectedRoute
        path={REPORT_NOT_ROLL_CALL_ROUTE}
        key={REPORT_NOT_ROLL_CALL_ROUTE}
        component={ReportNotRollCall}
        auth={true}
      />
    </Switch>
  );
}

export { ReportNotRollCallView };
export default ReportNotRollCallView;
