import PropTypes from "prop-types";
import React from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import profileMenuStyle from "./ProfileMenu.module.scss";
//i18n
import { useTranslation, withTranslation } from "react-i18next";
// Redux
import { Login16, Repeat16, User16 } from "@carbon/icons-react";
import Avatar, { ConfigProvider } from "react-avatar";
import { withRouter } from "react-router-dom";
import { useProfileMenu } from "./ProfileMenuHook";
import { detailService } from "services/page-services/detail-service";
import { AppUser } from "models/AppUser";
import { profileRepository } from "repositories/profile-repository";
import ChangePass from "pages/Authentication/ChangePass/ChangePass";
import { appUserRepository } from "repositories/app-user-repository";
import { webService } from "services/common-services/web-service";
import { finalize } from "rxjs/operators";
import appMessageService from "services/common-services/app-message-service";
import { AxiosError } from "axios";

const ProfileMenu = (props: any) => {
  const [translate] = useTranslation();

  const {
    handleToggerProfile,
    handleMouseLeaveAll,
    profileDrop,
    user,
    handleClickToProfile,
    handleLogout,
  } = useProfileMenu();

  const {
    model,
    dispatch,
    isOpenDetailModal,
    setIsOpenDetailModal,
    setLoadingModel,
    handleOpenDetailModal,
    handleCloseDetailModal,
    loadingModel,
    handleChangeSingleField,
    handleChangeAllField,
  } = detailService.useDetailModal(
    AppUser,
    profileRepository.get,
    profileRepository.changePassword
  );
  const [subscription] = webService.useSubscription();
  const { notifyUpdateItemSuccess, notifyUpdateItemError } =
    appMessageService.useCRUDMessage();

  const handleSaveChangePassModel = React.useCallback(() => {
    setLoadingModel(true);
    subscription.add(
      profileRepository
        .changePassword({
          ...new AppUser(),
          id: model?.id,
          oldPassword: model?.oldPassword,
          newPassword: model?.newPassword,
        })
        .pipe(finalize(() => setLoadingModel(false)))
        .subscribe({
          next: (item: AppUser) => {
            handleChangeAllField(item);
            setIsOpenDetailModal(false);
            notifyUpdateItemSuccess({
              message: "Cập nhật thành công",
              className: "antd-notification-drawer",
            });
          },
          error: (error: AxiosError<AppUser>) => {
            if (error.response && error.response.status === 400)
              handleChangeAllField(error.response?.data);
            notifyUpdateItemError({
              message: "Cập nhật thất bại",
              className: "antd-notification-drawer",
            });
          },
        })
    );
  }, [
    setLoadingModel,
    subscription,
    model?.id,
    model?.oldPassword,
    model?.newPassword,
    handleChangeAllField,
    setIsOpenDetailModal,
    notifyUpdateItemSuccess,
    notifyUpdateItemError,
  ]);

  return (
    <React.Fragment>
      <Dropdown isOpen={profileDrop} toggle={handleToggerProfile}>
        <DropdownToggle
          className={`btn ${profileMenuStyle["button-header-profile"]}`}
          id="page-header-user-dropdown"
          tag="button"
        >
          {user?.avatar ? (
            <img
              className="rounded-circle header-profile-user"
              src={user?.avatar}
              alt="Header Avatar"
            />
          ) : (
            <ConfigProvider colors={["red", "green", "blue"]}>
              <Avatar
                maxInitials={1}
                round={true}
                size="22"
                name={user?.displayName}
              />
            </ConfigProvider>
          )}
        </DropdownToggle>
        <DropdownMenu
          className={`dropdown-menu-end p-l--xxs ${profileMenuStyle["profile_menu_warapper"]}`}
        >
          <div className="">
            <div
              className={` ${profileDrop ? "active" : ""}`}
              onMouseLeave={handleMouseLeaveAll}
            >
              <div onClick={handleClickToProfile()} className="d-flex p-b--xxs">
                <User16 />
                <div>
                  <span className="p-l--xxxs">{user?.displayName} </span>
                </div>
              </div>
              <div
                onClick={() => handleOpenDetailModal(user?.id)}
                className={`p-b--xxs`}
              >
                <Repeat16 />
                <span className="p-l--xxxs">
                  {translate("general.actions.changePass")}
                </span>
              </div>

              <li onClick={handleLogout}>
                <Login16 />
                <span className="p-l--xxxs">
                  {translate("general.defaultHeader.logout")}
                </span>
              </li>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
      {isOpenDetailModal && (
        <ChangePass
          visible={isOpenDetailModal}
          dispatch={dispatch}
          loading={loadingModel}
          model={model}
          handleSave={handleSaveChangePassModel}
          handleChangeSingleField={handleChangeSingleField}
          handleCancel={handleCloseDetailModal}
        />
      )}
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(ProfileMenu) as any);
