/* begin general import */
import { Col, Row, Switch } from "antd";
import { ASSETS_SVG, STATUS_ENUM } from "config/consts";
import { Subject } from "models/Subject";
import React from "react";
import { useTranslation } from "react-i18next";
import Drawer, {
  DrawerProps,
} from "react3l-ui-library/build/components/Drawer/Drawer";
import FormItem from "react3l-ui-library/build/components/FormItem";
import InputText from "react3l-ui-library/build/components/Input/InputText";
import { utilService } from "services/common-services/util-service";
import { ModelAction } from "services/page-services/detail-service";
import nameof from "ts-nameof.macro";

/* end individual import */

interface SubjectDetailDrawerProps extends DrawerProps {
  model: Subject;
  handleChangeSingleField?: (config: {
    fieldName: string;
  }) => (value: any) => void;
  dispatch?: React.Dispatch<ModelAction<Subject>>;
  loading?: boolean;
}

function SubjectDetailDrawer(props: SubjectDetailDrawerProps) {
  const [translate] = useTranslation();

  const {
    model,
    handleChangeSingleField,
    loading,
    visible,
    handleSave,
    handleCancel,
  } = props;

  return (
    <Drawer
      {...props}
      visible={visible}
      handleSave={handleSave}
      handleCancel={handleCancel}
      handleClose={handleCancel}
      visibleFooter={true}
      title={
        model?.id
          ? translate("general.detail.title")
          : translate("general.actions.create")
      }
      titleButtonCancel={translate("general.actions.cancel")}
      titleButtonApply={translate("general.actions.save")}
    >
      {loading ? (
        <div className="loading-block">
          <img src={ASSETS_SVG + "/spinner.svg"} alt="Loading..." />
        </div>
      ) : (
        <div className="page page__detail">
          <div className="w-100 page__detail-tabs">
            <Row className="d-flex">
              <Col lg={24}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col lg={24} className="m-b--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.code)
                      )}
                      message={model.errors?.code}
                    >
                      <InputText
                        label={translate("subjects.code")}
                        isRequired
                        type={0}
                        value={model.code}
                        placeHolder={translate("subjects.placeholder.code")}
                        className={"tio-account_square_outlined"}
                        onChange={handleChangeSingleField({
                          fieldName: nameof(model.code),
                        })}
                        showCount
                        maxLength={20}
                      />
                    </FormItem>
                  </Col>

                  <Col lg={24} className="m-b--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.name)
                      )}
                      message={model.errors?.name}
                    >
                      <InputText
                        label={translate("subjects.name")}
                        isRequired={true}
                        type={0}
                        value={model.name}
                        placeHolder={translate("subjects.placeholder.name")}
                        className={"tio-account_square_outlined"}
                        onChange={handleChangeSingleField({
                          fieldName: nameof(model.name),
                        })}
                        maxLength={255}
                        showCount
                      />
                    </FormItem>
                  </Col>

                  <Col lg={24} className="m-b--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.status)
                      )}
                      message={model.errors?.status}
                    >
                      <div>
                        <div className="label-status">
                          {translate("subjects.status")}
                        </div>
                        <Switch
                          checked={model.statusId === 1 ? true : false}
                          onChange={(checked: boolean) => {
                            handleChangeSingleField({
                              fieldName: "statusId",
                            })(checked ? 1 : 0);
                            handleChangeSingleField({
                              fieldName: "status",
                            })(checked ? STATUS_ENUM[1] : STATUS_ENUM[0]);
                          }}
                          className="switch_status"
                        />
                      </div>
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Drawer>
  );
}

export default SubjectDetailDrawer;
