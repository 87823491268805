import { Repository } from "react3l-common";
import { kebabCase } from "lodash";
import { httpConfig } from "config/http";
import { BASE_API_URL } from "config/consts";
import { Observable } from "rxjs";
import { AxiosResponse } from "axios";
import nameof from "ts-nameof.macro";
import { API_REPORT_PREFIX } from "config/api-consts";
import { Report, ReportFilter } from "models/Report";
import { Semester, SemesterFilter } from "models/Semester";
import { CourseSection, CourseSectionFilter } from "models/CourseSection";
import { AppUser, AppUserFilter } from "models/AppUser";
import { Major, MajorFilter } from "models/Major";
import { Organization, OrganizationFilter } from "models/Organization";
import { Subject, SubjectFilter } from "models/Subject";
import { Student, StudentFilter } from "models/Student";

export class ReportRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = new URL(API_REPORT_PREFIX, BASE_API_URL).href;
  }

  public countReportByCourseSection = (
    reportFilter?: ReportFilter
  ): Observable<number> => {
    return this.http
      .post<number>(
        kebabCase(nameof(this.countReportByCourseSection)),
        reportFilter
      )
      .pipe(Repository.responseDataMapper<number>());
  };

  public listReportByCourseSection = (
    reportFilter?: ReportFilter
  ): Observable<Report[]> => {
    return this.http
      .post<Report[]>(
        kebabCase(nameof(this.listReportByCourseSection)),
        reportFilter
      )
      .pipe(Repository.responseMapToList<Report>(Report));
  };

  public exportReportByCourseSection = (
    filter: any
  ): Observable<AxiosResponse<any>> => {
    return this.http.post("export-report-by-course-section", filter, {
      responseType: "arraybuffer",
    });
  };

  public countReportByStudent = (
    reportFilter?: ReportFilter
  ): Observable<number> => {
    return this.http
      .post<number>(kebabCase(nameof(this.countReportByStudent)), reportFilter)
      .pipe(Repository.responseDataMapper<number>());
  };

  public listReportByStudent = (
    reportFilter?: ReportFilter
  ): Observable<Report[]> => {
    return this.http
      .post<Report[]>(kebabCase(nameof(this.listReportByStudent)), reportFilter)
      .pipe(Repository.responseMapToList<Report>(Report));
  };

  public exportReportByStudent = (
    filter: any
  ): Observable<AxiosResponse<any>> => {
    return this.http.post("export-report-by-student", filter, {
      responseType: "arraybuffer",
    });
  };

  public countReportByMajor = (
    reportFilter?: ReportFilter
  ): Observable<number> => {
    return this.http
      .post<number>(kebabCase(nameof(this.countReportByMajor)), reportFilter)
      .pipe(Repository.responseDataMapper<number>());
  };

  public listReportByMajor = (
    reportFilter?: ReportFilter
  ): Observable<Report[]> => {
    return this.http
      .post<Report[]>(kebabCase(nameof(this.listReportByMajor)), reportFilter)
      .pipe(Repository.responseMapToList<Report>(Report));
  };

  public exportReportByMajor = (
    filter: any
  ): Observable<AxiosResponse<any>> => {
    return this.http.post("export-report-by-major", filter, {
      responseType: "arraybuffer",
    });
  };

  public listReportNotRollCall = (
    reportFilter?: ReportFilter
  ): Observable<Report[]> => {
    return this.http
      .post<Report[]>(
        kebabCase(nameof(this.listReportNotRollCall)),
        reportFilter
      )
      .pipe(Repository.responseMapToList<Report>(Report));
  };

  public exportReportNotRollCall = (
    filter: any
  ): Observable<AxiosResponse<any>> => {
    return this.http.post("export-report-not-roll-call", filter, {
      responseType: "arraybuffer",
    });
  };

  public countReportRollCallDetail = (
    reportFilter?: ReportFilter
  ): Observable<number> => {
    return this.http
      .post<number>(
        kebabCase(nameof(this.countReportRollCallDetail)),
        reportFilter
      )
      .pipe(Repository.responseDataMapper<number>());
  };

  public listReportRollCallDetail = (
    reportFilter?: ReportFilter
  ): Observable<Report[]> => {
    return this.http
      .post<Report[]>(
        kebabCase(nameof(this.listReportRollCallDetail)),
        reportFilter
      )
      .pipe(Repository.responseMapToList<Report>(Report));
  };

  public exportReportRollCallDetail = (
    filter: any
  ): Observable<AxiosResponse<any>> => {
    return this.http.post("export-report-roll-call-detail", filter, {
      responseType: "arraybuffer",
    });
  };

  public filterListSemester = (
    semesterFilter: SemesterFilter
  ): Observable<Semester[]> => {
    return this.http
      .post<Semester[]>(
        kebabCase(nameof(this.filterListSemester)),
        semesterFilter
      )
      .pipe(Repository.responseMapToList<Semester>(Semester));
  };

  public filterListCourseSection = (
    courseSectionFilter: CourseSectionFilter
  ): Observable<CourseSection[]> => {
    return this.http
      .post<CourseSection[]>(
        kebabCase(nameof(this.filterListCourseSection)),
        courseSectionFilter
      )
      .pipe(Repository.responseMapToList<CourseSection>(CourseSection));
  };

  public filterListSubject = (
    subjectFilter: SubjectFilter
  ): Observable<Subject[]> => {
    return this.http
      .post<Subject[]>(kebabCase(nameof(this.filterListSubject)), subjectFilter)
      .pipe(Repository.responseMapToList<Subject>(Subject));
  };

  public filterListOrganization = (
    organizationFilter: OrganizationFilter
  ): Observable<Organization[]> => {
    return this.http
      .post<Organization[]>(
        kebabCase(nameof(this.filterListOrganization)),
        organizationFilter
      )
      .pipe(Repository.responseMapToList<Organization>(Organization));
  };

  public filterListAppUser = (
    appUserFilter: AppUserFilter
  ): Observable<AppUser[]> => {
    return this.http
      .post<AppUser[]>(kebabCase(nameof(this.filterListAppUser)), appUserFilter)
      .pipe(Repository.responseMapToList<AppUser>(AppUser));
  };

  public filterListStudent = (
    studentFilter: StudentFilter
  ): Observable<Student[]> => {
    return this.http
      .post<Student[]>(kebabCase(nameof(this.filterListStudent)), studentFilter)
      .pipe(Repository.responseMapToList<Student>(Student));
  };

  public filterListMajor = (marjorFilter: MajorFilter): Observable<Major[]> => {
    return this.http
      .post<Major[]>(kebabCase(nameof(this.filterListMajor)), marjorFilter)
      .pipe(Repository.responseMapToList<Major>(Major));
  };
}

export const reportRepository = new ReportRepository();
