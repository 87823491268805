import { Gender } from "models/Gender";
import { Major } from "models/Major";
import { Status } from "models/Status";
import type { Moment } from "moment";
import { Model } from "react3l-common";
import { Field, MomentField } from "react3l-decorators";

export class Student extends Model {
  @Field(Number)
  public id?: number;

  @Field(String)
  public code?: string;

  @Field(String)
  public className?: string;

  @Field(String)
  public cohort?: string;

  @Field(Number)
  public statusId?: number;

  @Field(Number)
  public majorId?: number;

  @Field(Number)
  public genderId?: number;

  @Field(String)
  public name?: string;

  @MomentField()
  public createdAt?: Moment;

  @MomentField()
  public updatedAt?: Moment;

  public major?: Major;

  public status?: Status;

  public gender?: Gender;
}
