export const THOUSAND_SEPARATOR: string = ",";

export const DECIMAL_SEPARATOR: string = ".";

export function formatNumber(
  x: number | string,
  numberAfterComma?: number,
  isReverseSymb = false
): string | null {
  const isNumber: boolean = typeof x === "number";
  if (isNumber) {
    if (typeof numberAfterComma !== "undefined") {
      const offset = Math.pow(10, numberAfterComma);
      x = (
        Math.round(((x as number) + Number.EPSILON) * offset) / offset
      ).toFixed(numberAfterComma);
    }

    const parts: string[] = x.toString().split(DECIMAL_SEPARATOR);
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, THOUSAND_SEPARATOR);
    return isReverseSymb
      ? parts
          .join(DECIMAL_SEPARATOR)
          .replace(/[,.]/g, (m) => (m === "," ? "." : ","))
      : parts.join(DECIMAL_SEPARATOR);
  }
  if (x) {
    return isReverseSymb
      ? x.toString().replace(/[,.]/g, (m) => (m === "," ? "." : ","))
      : x.toString();
  }
  return null;
}

export function parseNumber(formattedNumber: string): number | null {
  const parts: string[] = formattedNumber.split(DECIMAL_SEPARATOR, 2);
  parts[0] = parts[0].split(THOUSAND_SEPARATOR).join("");
  let result: number;
  if (parts.length === 1) {
    result = parseInt(parts[0], 10);
  } else {
    result = parseFloat(parts.join("."));
  }
  if (Number.isNaN(result)) {
    return null;
  }
  return result;
}
