import { Model } from "react3l-common";
import { Field, MomentField } from "react3l-decorators";
import type { Moment } from "moment";
import { AppUser } from "models/AppUser";
import { CourseSection } from "models/CourseSection";
import { Semester } from "models/Semester";
import { Subject } from "models/Subject";
import { Status } from "models/Status";
import { CourseSectionAppUserMapping } from "models/CourseSectionAppUserMapping";

export class RollCall extends Model {
  @Field(Number)
  public id?: number;

  @Field(Number)
  public courseSectionId?: number;

  @Field(Number)
  public appUserId?: number;

  @MomentField()
  public checkinAt?: Moment;

  @MomentField()
  public lessonDate?: Moment;

  @Field(Number)
  public indexOfLesson?: number;

  @Field(String)
  public code?: string;

  @Field(Number)
  public statusId?: number;

  @Field(Number)
  public semesterId?: number;

  @Field(Number)
  public subjectId?: number;

  @Field(String)
  public name?: string;

  @Field(Boolean)
  public isStartSameSemester?: boolean;

  @MomentField()
  public startedAt?: Moment;

  @MomentField()
  public endedAt?: Moment;

  @Field(Number)
  public numberOfCreditHours?: number;

  @Field(Number)
  public numberStudent?: number;

  public semester?: Semester;

  public subject?: Subject;

  public status?: Status;

  public courseSectionAppUserMappings?: CourseSectionAppUserMapping[];

  public appUser?: AppUser;

  public courseSection?: CourseSection;
}
