import { SEMESTER_MASTER_ROUTE } from "config/route-consts";
import { ProtectedRoute } from "pages/Authentication/ProtectedRoute";
import { Switch } from "react-router-dom";
import SemesterMaster from "./SemesterMaster/SemesterMaster";

function SemesterView() {
  return (
    <Switch>
      <ProtectedRoute
        path={SEMESTER_MASTER_ROUTE}
        key={SEMESTER_MASTER_ROUTE}
        component={SemesterMaster}
        auth={true}
      />
    </Switch>
  );
}

export { SemesterMaster };
export default SemesterView;
