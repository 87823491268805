import { IdFilter, NumberFilter, StringFilter } from "react3l-advanced-filters";
import { ModelFilter } from "react3l-common";

export class CourseSectionShiftFilter extends ModelFilter {
  public id?: IdFilter = new IdFilter();
  public weekdayId?: IdFilter = new IdFilter();
  public courseSectionId?: IdFilter = new IdFilter();
  public classShiftId?: IdFilter = new IdFilter();
  public startedHour?: NumberFilter = new NumberFilter();
  public endedHour?: NumberFilter = new NumberFilter();
  public room?: StringFilter = new StringFilter();
}
