import { Major } from "models/Major";
import React, { useMemo } from "react";
import {
  FilterAction,
  filterReducer,
  filterService,
} from "services/page-services/filter-service";
import { GuidFilter, IdFilter } from "react3l-advanced-filters";
import { detailService } from "services/page-services/detail-service";
import { tableService } from "services/page-services/table-service";
import memoize from "fast-memoize";
import { Model } from "react3l-common";
import _ from "lodash";
import { Subject, SubjectFilter } from "models/Subject";
import { majorRepository } from "repositories/major-repository";

export function useMajorContentModal(
  model: Major,
  setModel: (major: Major) => void
) {
  const [subjectFilter, dispatchSubjectFilter] = React.useReducer<
    React.Reducer<SubjectFilter, FilterAction<SubjectFilter>>
  >(filterReducer, { ...new SubjectFilter(), skip: 0, take: 10 });

  const mappingField: [string, string] = React.useMemo(() => {
    return ["subjectId", "subject"];
  }, []);

  const {
    value: filter,
    handleChangeAllFilter: handleChangeSubjectAllFilter,
    handleChangeInputSearch,
  } = filterService.useFilter(subjectFilter, dispatchSubjectFilter);

  const tmpFilter = React.useMemo(() => {
    return {
      ...filter,
      statusId: {
        equal: 1,
      },
    };
  }, [filter]);

  const mappingData = React.useCallback(
    (selectedList: Subject[]) => {
      const list: any = selectedList.map((item: Subject) => {
        return {
          majorId: model?.id,
          subjectId: item.id,
          subject: item,
        };
      });
      return list;
    },
    [model]
  );

  const {
    open: visible,
    listMapping,
    countMapping,
    checkedKeys,
    spinning,
    handleOpenMapping,
    handleCloseMapping,
    handleSaveMapping: handleSaveMappingSubject,
    handleGetListMapping,
    handleCancelMapping,
    handleCheck,
    handleCheckAll,
  } = detailService.useMappingService(
    majorRepository.listSubject,
    majorRepository.countSubject,
    mappingData,
    tmpFilter,
    model.majorSubjectMappings,
    mappingField
  );

  const handleSaveMapping = React.useCallback(() => {
    const majorSubjectMappings = handleSaveMappingSubject();
    setModel({ ...model, majorSubjectMappings });
    handleChangeSubjectAllFilter(new SubjectFilter());
  }, [handleChangeSubjectAllFilter, handleSaveMappingSubject, model, setModel]);

  const handleCloseMappingModal = React.useCallback(() => {
    handleChangeSubjectAllFilter(new SubjectFilter());
    handleCloseMapping();
  }, [handleChangeSubjectAllFilter, handleCloseMapping]);

  const handleChangeSubjectModalFilter = React.useCallback(
    (value) => {
      handleChangeSubjectAllFilter(value);
      handleGetListMapping(value);
    },
    [handleChangeSubjectAllFilter, handleGetListMapping]
  );

  const handleChangerRowSelection = React.useCallback(
    (record) => {
      const selected = checkedKeys?.includes(record?.id) ? false : true;

      handleCheck(record, selected);
    },
    [checkedKeys, handleCheck]
  );
  const rowSelection = useMemo(
    () => ({
      onSelect(record: Subject, selected: boolean) {
        handleCheck(record, selected);
      },
      onSelectAll(
        selected: boolean,
        selectedRows: Subject[],
        changeRows: Subject[]
      ) {
        handleCheckAll(selected, selectedRows, changeRows);
      },
      selectedRowKeys: checkedKeys,
    }),
    [checkedKeys, handleCheck, handleCheckAll]
  );
  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeSubjectModalFilter
  );

  const handleChangeSelectFilter = React.useMemo(
    () =>
      memoize(
        (config: {
            fieldName: string;
            fieldType: string;
            classFilter: new (partial?: any) => IdFilter | GuidFilter;
          }) =>
          (idValue: number, value: Model) => {
            const { fieldName, fieldType, classFilter: ClassFilter } = config;
            const newFilter = {
              ...subjectFilter,
              [`${fieldName}Value`]: value,
              [`${fieldName}Id`]: Object.assign(new ClassFilter(), {
                [fieldType]: idValue,
              }),
              skip: 0,
            };
            handleChangeSubjectModalFilter(newFilter);
          }
      ),
    [handleChangeSubjectModalFilter, subjectFilter]
  );

  const handleChangeSelectTypeFilter = React.useMemo(
    () =>
      memoize(
        (config: {
            fieldName: string;
            fieldType: string;
            classFilter: new (partial?: any) => IdFilter | GuidFilter;
          }) =>
          (idValue: number, value: Model) => {
            const { fieldName, fieldType, classFilter: ClassFilter } = config;
            const newFilter =
              idValue === 1
                ? {
                    ...subjectFilter,
                    [`${fieldName}Value`]: value,
                    [`${fieldName}Id`]: Object.assign(new ClassFilter(), {
                      [fieldType]: idValue,
                    }),
                    id: {
                      in: checkedKeys,
                    },
                    skip: 0,
                  }
                : idValue === 2
                ? {
                    ...subjectFilter,
                    [`${fieldName}Value`]: value,
                    [`${fieldName}Id`]: Object.assign(new ClassFilter(), {
                      [fieldType]: idValue,
                    }),
                    id: {
                      notIn: checkedKeys?.length > 0 ? checkedKeys : undefined,
                    },
                    skip: 0,
                  }
                : {
                    ...subjectFilter,
                    [`${fieldName}Value`]: value,
                    [`${fieldName}Id`]: Object.assign(new ClassFilter(), {
                      [fieldType]: idValue,
                    }),
                    id: new IdFilter(),
                    skip: 0,
                  };
            handleChangeSubjectModalFilter(newFilter);
          }
      ),
    [checkedKeys, handleChangeSubjectModalFilter, subjectFilter]
  );

  const handleChangeInputFilter = React.useCallback(
    (value: any) => {
      const newFilter = {
        ...subjectFilter,
        search: value,
        skip: 0,
      };
      handleChangeSubjectAllFilter(newFilter);
      handleGetListMapping(newFilter);
    },
    [handleChangeSubjectAllFilter, handleGetListMapping, subjectFilter]
  );
  const handleChangeTagFilter = React.useCallback(
    (valueFilter: any) => {
      if (!valueFilter?.selectTypeId?.equal) {
        valueFilter.id = new IdFilter();
      }
      const newFilter = {
        ...valueFilter,
      };
      handleChangeSubjectAllFilter(newFilter);
      handleGetListMapping(newFilter);
    },
    [handleChangeSubjectAllFilter, handleGetListMapping]
  );
  return {
    open: visible,
    listMapping,
    countMapping,
    subjectFilter,
    spinning,
    rowSelection,
    handleOpenMapping,
    handleCloseMapping: handleCloseMappingModal,
    handleSaveMapping,
    handleCancelMapping,
    handleChangeTagFilter,
    handleChangeSubjectAllFilter,
    handleChangeSelectFilter,
    handleChangeInputSearch,
    handleTableChange,
    handlePagination,
    handleChangeInputFilter,
    handleChangeSelectTypeFilter,
    handleChangerRowSelection,
  };
}
