/* begin general import */
import _ from "lodash";
import { detailService } from "services/page-services/detail-service";
import { Student } from "models/Student";
import { studentRepository } from "repositories/student-repository";
import { fieldService } from "services/page-services/field-service";
import { STUDENT_ROUTE } from "config/route-consts";

/* end individual import */

export default function useStudentDetail() {
  // use hook
  const { model, dispatch } = detailService.useModel<Student>(Student);

  const { isDetail } = detailService.useGetIsDetail<Student>(
    studentRepository.get,
    dispatch
  );

  const {
    handleChangeDateField,
    handleChangeAllField,
    handleChangeSingleField,
    handleChangeSelectField,
  } = fieldService.useField(model, dispatch);

  const { handleSaveModel, handleGoMaster } =
    detailService.useActionsDetail<Student>(
      model,
      studentRepository.save,
      handleChangeAllField,
      STUDENT_ROUTE
    );

  return {
    model,
    isDetail,
    dispatch,
    handleChangeDateField,
    handleChangeSingleField,
    handleChangeSelectField,
    handleChangeAllField,
    handleGoMaster,
    handleSaveModel,
  };
}
