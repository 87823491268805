// import RightSidebar from "components/CommonForBoth/RightSidebar/RightSidebar";
import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu/ProfileMenu";
import PropTypes from "prop-types";
import React from "react";
import "react-drawer/lib/react-drawer.css";
//i18n
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { showRightSidebarAction, toggleLeftmenu } from "store/actions";
import Navbar from "../Navbar/Navbar";
import "./Header.module.scss";
import headerStyle from "./Header.module.scss";
import { AppUserSiteMapping } from "./HeaderHook";
import logo from "assets/images/TVUlogo.png";

interface Document extends HTMLDocument {
  mozFullScreenElement: any;
  webkitFullscreenElement: any;
  mozRequestFullScreen: any;
  webkitRequestFullscreen: any;
  cancelFullScreen: () => void;
  mozCancelFullScreen: () => void;
  webkitCancelFullScreen: () => void;
}

const Header = (props: any) => {
  const currentUserInfo = JSON.parse(localStorage.getItem("currentUserInfo"));

  const currentSite = React.useMemo(() => {
    if (
      currentUserInfo &&
      currentUserInfo?.appUserSiteMappings &&
      currentUserInfo?.appUserSiteMappings.length > 0
    ) {
      return currentUserInfo.appUserSiteMappings.filter(
        (item: AppUserSiteMapping) => {
          return item.site.code.includes(process.env.PUBLIC_URL);
        }
      )[0]?.site;
    }
  }, [currentUserInfo]);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !(document as Document)
        .mozFullScreenElement &&
      !(document as Document).webkitFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      }
    } else {
      if ((document as Document).cancelFullScreen) {
        (document as Document).cancelFullScreen();
      } else if ((document as Document).mozCancelFullScreen) {
        (document as Document).mozCancelFullScreen();
      } else if ((document as Document).webkitCancelFullScreen) {
        (document as Document).webkitCancelFullScreen();
      }
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex navbar-header--inner align-items-center">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="32" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="32" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="32" />
                  {/* {currentSite?.name} */}
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="32" />
                  {/* {currentSite?.name} */}
                </span>
              </Link>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <div className="navbar-menu navbar-menu--sm">
              <Navbar />
            </div>
          </div>

          <div className="d-flex navbar-header--inner align-items-center">
            <div className={headerStyle["button-header"]}>
              <button
                type="button"
                className={`btn noti-icon ${headerStyle["button-header-full-screen"]}`}
                onClick={() => {
                  toggleFullscreen();
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state: any) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
