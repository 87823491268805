import { translate } from "i18n/i18n";
import { TFunction } from "i18next";
import { ReactNode } from "react";
import {
  APP_USER_MASTER_ROUTE,
  CATEGORY_MANAGEMENT_ROUTE,
  COURSE_SECTION_MASTER_ROUTE,
  MAJOR_MASTER_ROUTE,
  REPORT_BY_COURSE_SECTION_ROUTE,
  REPORT_BY_MAJOR_ROUTE,
  REPORT_BY_STUDENT_ROUTE,
  REPORT_NOT_ROLL_CALL_ROUTE,
  REPORT_ROLL_CALL_DETAIL_ROUTE,
  REPORT_ROUTE,
  ROLL_CALL_MASTER_ROUTE,
  SEMESTER_MASTER_ROUTE,
  STUDENT_MASTER_ROUTE,
  SUBJECT_MASTER_ROUTE,
} from "./route-consts";

export interface Menu {
  name?: string | TFunction;
  icon?: string | ReactNode;
  link: string;
  children?: Menu[];
  active?: boolean;
  show?: boolean;
  checkVisible?: (mapper: Record<string, number>) => boolean;
}

export const menu: Menu[] = [
  {
    name: translate("menu.title.appUser"),
    icon: "bx-pen",
    link: APP_USER_MASTER_ROUTE,
    active: false,
    show: true,
    checkVisible: checkVisible(APP_USER_MASTER_ROUTE),
  },

  {
    name: translate("menu.title.categoryManagement"),
    link: CATEGORY_MANAGEMENT_ROUTE,
    show: true,
    active: true,
    icon: "bx-book",
    checkVisible: checkVisible(CATEGORY_MANAGEMENT_ROUTE),
    children: [
      {
        name: translate("menu.title.student"),
        icon: "bx-group",
        link: STUDENT_MASTER_ROUTE,
        active: false,
        show: true,
        checkVisible: checkVisible(STUDENT_MASTER_ROUTE),
      },
      {
        name: translate("menu.title.major"),
        icon: "bx-home",
        link: MAJOR_MASTER_ROUTE,
        active: false,
        show: true,
        checkVisible: checkVisible(MAJOR_MASTER_ROUTE),
      },
      {
        name: translate("menu.title.subject"),
        icon: "bx-box",
        link: SUBJECT_MASTER_ROUTE,
        active: false,
        show: true,
        checkVisible: checkVisible(SUBJECT_MASTER_ROUTE),
      },
      {
        name: translate("menu.title.semester"),
        icon: "bx-book",
        link: SEMESTER_MASTER_ROUTE,
        active: false,
        show: true,
        checkVisible: checkVisible(SEMESTER_MASTER_ROUTE),
      },
      {
        name: translate("menu.title.courseSection"),
        icon: "bx-group",
        link: COURSE_SECTION_MASTER_ROUTE,
        active: false,
        show: true,
        checkVisible: checkVisible(COURSE_SECTION_MASTER_ROUTE),
      },
    ],
  },

  {
    name: translate("menu.title.rollCall"),
    icon: "bx-timer",
    link: ROLL_CALL_MASTER_ROUTE,
    active: false,
    show: true,
    checkVisible: checkVisible(ROLL_CALL_MASTER_ROUTE),
  },
  {
    name: translate("menu.title.report"),
    link: REPORT_ROUTE,
    show: true,
    active: true,
    icon: "bxs-report",
    checkVisible: checkVisible(REPORT_ROUTE),
    children: [
      {
        name: translate("menu.title.reportRollCallDetail"),
        icon: "bx-group",
        link: REPORT_ROLL_CALL_DETAIL_ROUTE,
        active: false,
        show: true,
        checkVisible: checkVisible(REPORT_ROLL_CALL_DETAIL_ROUTE),
      },
      {
        name: translate("menu.title.reportByCourseSection"),
        icon: "bx-home",
        link: REPORT_BY_COURSE_SECTION_ROUTE,
        active: false,
        show: true,
        checkVisible: checkVisible(REPORT_BY_COURSE_SECTION_ROUTE),
      },
      {
        name: translate("menu.title.reportByStudent"),
        icon: "bx-home",
        link: REPORT_BY_STUDENT_ROUTE,
        active: false,
        show: true,
        checkVisible: checkVisible(REPORT_BY_STUDENT_ROUTE),
      },
      {
        name: translate("menu.title.reportByMajor"),
        icon: "bx-home",
        link: REPORT_BY_MAJOR_ROUTE,
        active: false,
        show: true,
        checkVisible: checkVisible(REPORT_BY_MAJOR_ROUTE),
      },
      {
        name: translate("menu.title.reportNotRollCall"),
        icon: "bx-box",
        link: REPORT_NOT_ROLL_CALL_ROUTE,
        active: false,
        show: true,
        checkVisible: checkVisible(REPORT_NOT_ROLL_CALL_ROUTE),
      },
    ],
  },
];
function checkVisible(
  ...urls: string[]
): (object: Record<string, number>) => boolean {
  return (object: Record<string, number>) => {
    let display = false;
    if (urls.length > 0) {
      urls.forEach((item) => {
        // eslint-disable-next-line no-prototype-builtins
        if (object.hasOwnProperty(item)) display = true;
      });
    }
    return display;
  };
}
