import { Model } from "react3l-common";
import { Field, MomentField } from "react3l-decorators";
import type { Moment } from "moment";
import { Status } from "models/Status";

export class RoleType extends Model {
  @Field(Number)
  public id?: number;

  @Field(String)
  public code?: string;

  @Field(String)
  public name?: string;

  @Field(Number)
  public parentId?: number;

  @Field(String)
  public path?: string;

  @Field(Number)
  public level?: number;

  @Field(Boolean)
  public hasChildren?: boolean;

  @Field(Number)
  public statusId?: number;

  @Field(String)
  public phone?: string;

  @Field(String)
  public email?: string;

  @Field(String)
  public address?: string;

  @Field(String)
  public taxCode?: string;

  @MomentField()
  public createdAt?: Moment;

  @MomentField()
  public updatedAt?: Moment;

  @MomentField()
  public deletedAt?: Moment;
  @Field(String)
  public rowId?: string;

  public status?: Status;

  public costCenterName?: string;
}
