import {
  APP_USER_DETAIL_ROUTE,
  APP_USER_MASTER_ROUTE,
} from "config/route-consts";
import { ProtectedRoute } from "pages/Authentication/ProtectedRoute";
import { Switch } from "react-router-dom";
import AppUserMaster from "./AppUserMaster/AppUserMaster";
import AppUserDetail from "./AppUserDetail/AppUserDetail";

function AppUserView() {
  return (
    <Switch>
      <ProtectedRoute
        path={APP_USER_MASTER_ROUTE}
        key={APP_USER_MASTER_ROUTE}
        component={AppUserMaster}
        auth={true}
      />
      <ProtectedRoute
        path={APP_USER_DETAIL_ROUTE}
        key={APP_USER_DETAIL_ROUTE}
        component={AppUserDetail}
        auth={true}
      />
    </Switch>
  );
}

export { AppUserMaster };
export default AppUserView;
