/* begin general import */
import { StudentFilter } from "models/Student";
import { StatusFilter } from "models/Status";
import React from "react";
import { useTranslation } from "react-i18next";
import { IdFilter, StringFilter } from "react3l-advanced-filters";
import { AdvanceDateRangeFilter, AdvanceIdFilter } from "react3l-ui-library";
import AdvanceStringFilter from "react3l-ui-library/build/components/AdvanceFilter/AdvanceStringFilter";
import Drawer, {
  DrawerProps,
} from "react3l-ui-library/build/components/Drawer/Drawer";
import { Reducer } from "redux";
import {
  FilterAction,
  filterReducer,
  filterService,
} from "services/page-services/filter-service";
import { studentRepository } from "repositories/student-repository";
import { GenderFilter } from "models/Gender";
import { MajorFilter } from "models/Major";

/* end individual import */

export interface StudentFilterProps extends DrawerProps {
  filter?: any;
  setVisible?: any;
  handleChangeAllFilter?: (data: any) => void;
}

function StudentAdvanceFilter(props: StudentFilterProps) {
  const [translate] = useTranslation();

  const { visible, filter, setVisible, handleChangeAllFilter, handleClose } =
    props;

  const [modelFilter, setModelFilter] = React.useReducer<
    Reducer<StudentFilter, FilterAction<StudentFilter>>
  >(filterReducer, filter);

  const {
    handleChangeInputFilter,
    handleChangeSelectFilter,
    handleChangeDateFilter,
  } = filterService.useFilter(filter, setModelFilter);

  const handleSaveModelFilter = React.useCallback(() => {
    handleChangeAllFilter(modelFilter);
    setVisible(false);
  }, [handleChangeAllFilter, modelFilter, setVisible]);

  const handleClearModelFilter = React.useCallback(() => {
    setModelFilter({
      type: 0,
      payload: new StudentFilter(),
    });
  }, [setModelFilter]);

  return (
    <Drawer
      visible={visible}
      handleSave={handleSaveModelFilter}
      handleCancel={handleClearModelFilter}
      handleClose={handleClose}
      visibleFooter={true}
      loading={false}
      size={"sm"}
      title={translate("general.drawer.filter")}
      titleButtonCancel={translate("general.actions.clear")}
      titleButtonApply={translate("general.actions.apply")}
    >
      <div className="d-flex m-t--sm">
        <AdvanceStringFilter
          label={translate("students.code")}
          maxLength={100}
          value={modelFilter["code"]["contain"]}
          onChange={handleChangeInputFilter({
            fieldName: "code",
            fieldType: "contain",
            classFilter: StringFilter,
          })}
          placeHolder={translate("students.placeholder.code")}
          showCount
        />
      </div>

      <div className="d-flex m-t--sm">
        <AdvanceStringFilter
          label={translate("students.name")}
          maxLength={100}
          value={modelFilter["name"]["contain"]}
          onChange={handleChangeInputFilter({
            fieldName: "name",
            fieldType: "contain",
            classFilter: StringFilter,
          })}
          placeHolder={translate("students.placeholder.name")}
          showCount
        />
      </div>

      <div className="d-flex m-t--sm">
        <AdvanceIdFilter
          value={modelFilter["genderValue"]}
          onChange={handleChangeSelectFilter({
            fieldName: "gender",
            fieldType: "equal",
            classFilter: IdFilter,
          })}
          classFilter={GenderFilter}
          getList={studentRepository.filterListGender}
          placeHolder={translate("students.placeholder.gender")}
          label={translate("students.gender")}
        />
      </div>

      <div className="d-flex m-t--sm">
        <AdvanceStringFilter
          label={translate("students.cohort")}
          maxLength={100}
          value={modelFilter["cohort"]["contain"]}
          onChange={handleChangeInputFilter({
            fieldName: "cohort",
            fieldType: "contain",
            classFilter: StringFilter,
          })}
          placeHolder={translate("students.placeholder.cohort")}
          showCount
        />
      </div>

      <div className="d-flex m-t--sm">
        <AdvanceStringFilter
          label={translate("students.className")}
          maxLength={100}
          value={modelFilter["className"]["contain"]}
          onChange={handleChangeInputFilter({
            fieldName: "className",
            fieldType: "contain",
            classFilter: StringFilter,
          })}
          placeHolder={translate("students.placeholder.className")}
          showCount
        />
      </div>

      <div className="d-flex m-t--sm">
        <AdvanceIdFilter
          value={modelFilter["majorValue"]}
          onChange={handleChangeSelectFilter({
            fieldName: "major",
            fieldType: "equal",
            classFilter: IdFilter,
          })}
          classFilter={MajorFilter}
          getList={studentRepository.filterListMajor}
          placeHolder={translate("students.placeholder.major")}
          label={translate("students.major")}
        />
      </div>

      <div className="d-flex m-t--sm">
        <AdvanceDateRangeFilter
          label={translate("students.doB")}
          onChange={handleChangeDateFilter({
            fieldName: "doB",
            fieldType: ["greaterEqual", "lessEqual"],
          })}
          value={[
            modelFilter?.doB?.greaterEqual as any,
            modelFilter?.doB?.lessEqual as any,
          ]}
          type={1}
          placeholder={[
            translate("general.filter.startDate"),
            translate("general.filter.endDate"),
          ]}
        />
      </div>
      <div className="d-flex m-t--sm">
        <AdvanceIdFilter
          value={modelFilter["statusValue"]}
          onChange={handleChangeSelectFilter({
            fieldName: "status",
            fieldType: "equal",
            classFilter: IdFilter,
          })}
          classFilter={StatusFilter}
          getList={studentRepository.filterListStatus}
          placeHolder={translate("students.placeholder.status")}
          label={translate("students.status")}
        />
      </div>
    </Drawer>
  );
}

export default StudentAdvanceFilter;
