import { ObjectList } from "react3l-decorators";
import { Major } from "./Major";
import nameof from "ts-nameof.macro";

ObjectList(Major)(
  Major.prototype,
  nameof(Major.prototype.majorSubjectMappings)
);

export * from "./Major";
export * from "./MajorFilter";
