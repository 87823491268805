/* begin general import */
import { Col, Row, Spin, Switch } from "antd";
import nameof from "ts-nameof.macro";
import { utilService } from "services/common-services/util-service";
import FormItem from "react3l-ui-library/build/components/FormItem";
import { Button, Select, StandardTable } from "react3l-ui-library";
import InputText from "react3l-ui-library/build/components/Input/InputText";
import { Add16, Close16, Save16, TrashCan16 } from "@carbon/icons-react";
import _ from "lodash";
import "./MajorDetail.scss";
import useMajorDetail from "./MajorDetailHook/MajorDetailHook";
import { useTranslation } from "react-i18next";
import { STATUS_ENUM } from "config/consts";
import { useMajorContentTable } from "./MajorDetailHook/MajorContentTableHook";
import { useMajorContentModal } from "./MajorDetailHook/MajorContentModalHook";
import { MajorContentModal } from "./MajorDetailHook/MajorContentModal";
import { Organization, OrganizationFilter } from "models/Organization";
import { majorRepository } from "repositories/major-repository";

/* end individual import */

function MajorDetail() {
  const [translate] = useTranslation();

  const {
    model,
    isDetail,
    handleChangeSingleField,
    handleChangeSelectField,
    handleChangeAllField,
    handleGoMaster,
    handleSaveModel,
  } = useMajorDetail();

  const {
    list: subjectList,
    selectedRowKeys,
    rowSelection,
    subjectMajorMappingColumns,
    handleDeleteItems,
  } = useMajorContentTable(model, handleChangeAllField);

  const {
    open: visibleSubjectModal,
    listMapping: listSubject,
    countMapping: countSubject,
    subjectFilter,
    spinning,
    handleOpenMapping: handleOpenSubjectModal,
    handleCloseMapping: handleCloseSubjectModal,
    handleSaveMapping: handleSaveSubjectModal,
    rowSelection: rowSelectionSubjectModal,
    handleTableChange: handleContentModalTableChange,
    handlePagination: handleChangePaginationSubjectModal,
    handleChangeInputFilter,
    handleChangerRowSelection,
  } = useMajorContentModal(model, handleChangeAllField);

  return (
    <Spin spinning={false}>
      <div className="page-content page-content-dxg" id="page-content-dxg-id">
        <div className="info-component m-l--sm m-r--xxl mb-3">
          <span>{translate("majors.master.header")}</span>
          <span> &gt; </span>
          <span className="info-primary">
            {!isDetail
              ? translate("general.actions.create")
              : translate("general.detail.title")}
          </span>
        </div>
        <div className="page page-detail">
          <div className="d-flex">
            <h5 className="font-bold page-title m-l--sm m-t--sm">
              {isDetail
                ? translate("majors.detail.titleDetail")
                : translate("majors.detail.titleCreate")}
            </h5>
            {/* Trạng thái */}
            <div className="m-t--sm m-l--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.status)
                )}
                message={model.errors?.status}
              >
                <Switch
                  checked={model.statusId === 1 ? true : false}
                  onChange={(checked: boolean) => {
                    handleChangeSingleField({
                      fieldName: "statusId",
                    })(checked ? 1 : 0);
                    handleChangeSingleField({
                      fieldName: "status",
                    })(checked ? STATUS_ENUM[1] : STATUS_ENUM[0]);
                  }}
                  className="switch_status"
                />
              </FormItem>
            </div>
          </div>
          <div className="page-detail__content">
            <div className="page-detail__title p-b--lg">
              {translate("majors.detail.generalTitle")}
            </div>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {/* Mã ngành học*/}
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.code)
                  )}
                  message={model.errors?.code}
                >
                  <InputText
                    label={translate("majors.code")}
                    type={0}
                    value={model.code}
                    placeHolder={translate("majors.placeholder.code")}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.code),
                    })}
                    isRequired
                    showCount
                    maxLength={20}
                  />
                </FormItem>
              </Col>
              {/* Tên ngành học */}
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.name)
                  )}
                  message={model.errors?.name}
                >
                  <InputText
                    label={translate("majors.name")}
                    type={0}
                    value={model.name}
                    placeHolder={translate("majors.placeholder.name")}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.name),
                    })}
                    isRequired
                    showCount
                    maxLength={255}
                  />
                </FormItem>
              </Col>
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.organization)
                  )}
                  message={model.errors?.organization}
                >
                  <Select
                    label={translate("majors.organization")}
                    type={0}
                    classFilter={OrganizationFilter}
                    placeHolder={translate("majors.placeholder.organization")}
                    getList={majorRepository.singleListOrganization}
                    onChange={handleChangeSelectField({
                      fieldName: nameof(model.organization),
                    })}
                    render={(organization: Organization) => {
                      return organization?.code && organization?.name
                        ? `${organization?.code} - ${organization?.name}`
                        : organization?.code
                        ? organization?.code
                        : null;
                    }}
                    value={model.organization}
                    isRequired
                  />
                </FormItem>
              </Col>
            </Row>
            <div className="p-t--lg p-b--space-ultra-wire">
              <div className="d-flex justify-content-between">
                <div className="page-detail__title p-b--lg">
                  {translate("majors.contents.title")}
                </div>
                <div className="d-flex align-items-center justify-content-right">
                  <Button
                    type="outline-primary"
                    className="btn--lg btn-orange-export ml-3"
                    icon={<Add16 />}
                    onClick={handleOpenSubjectModal}
                  >
                    {translate("majors.addMaterial")}
                  </Button>
                  <Button
                    type="danger"
                    className="btn--lg btn-delete-items btn-red ml-3 mr-4"
                    icon={<TrashCan16 />}
                    onClick={handleDeleteItems}
                    disabled={
                      selectedRowKeys?.length === 0 ||
                      model?.majorSubjectMappings?.length === 0
                    }
                  >
                    {translate("majors.deleteMaterial")}
                  </Button>
                </div>
              </div>

              <StandardTable
                rowKey={"subjectId"}
                columns={subjectMajorMappingColumns}
                dataSource={subjectList}
                rowSelection={rowSelection}
                isDragable={true}
                tableSize={"md"}
                scroll={{ y: 500 }}
                bordered
              />
            </div>
          </div>
        </div>
        <footer className="app-footer">
          <div className="app-footer__full d-flex justify-content-end align-items-center">
            <div className="app-footer__actions d-flex justify-content-end">
              <Button
                type="secondary"
                className="btn--lg btn-red"
                icon={<Close16 />}
                onClick={handleGoMaster}
              >
                {translate("general.actions.close")}
              </Button>

              <Button
                type="primary"
                className="btn--lg btn-blue ml-3"
                icon={<Save16 />}
                onClick={handleSaveModel}
              >
                {translate("general.actions.save")}
              </Button>
            </div>
          </div>
        </footer>
      </div>
      <MajorContentModal
        handleClose={handleCloseSubjectModal}
        handleSave={handleSaveSubjectModal}
        countSubject={countSubject}
        listSubject={listSubject}
        visible={visibleSubjectModal}
        handleChangeInputFilter={handleChangeInputFilter}
        subjectFilter={subjectFilter}
        handlePagination={handleChangePaginationSubjectModal}
        rowSelection={rowSelectionSubjectModal}
        loadList={spinning}
        handleTableChange={handleContentModalTableChange}
        handleChangerRowSelection={handleChangerRowSelection}
      />
    </Spin>
  );
}

export default MajorDetail;
