import { REPORT_BY_STUDENT_ROUTE } from "config/route-consts";
import { ProtectedRoute } from "pages/Authentication/ProtectedRoute";
import { Switch } from "react-router-dom";
import ReportByStudent from "./ReportByStudent";

function ReportByStudentView() {
  return (
    <Switch>
      <ProtectedRoute
        path={REPORT_BY_STUDENT_ROUTE}
        key={REPORT_BY_STUDENT_ROUTE}
        component={ReportByStudent}
        auth={true}
      />
    </Switch>
  );
}

export { ReportByStudentView };
export default ReportByStudentView;
