import { join } from "path";

export const API_PREFIX: string = "api/checkin/";
export const API_ACTION_PREFIX: string = "api/checkin/action";
export const API_USER_NOTIFICATION_ROUTE: string =
  "api/utils/user-notification";
export const API_APP_USER_PREFIX: string = join(API_PREFIX + "/app-user");
export const API_PROFILE_PREFIX: string = join(API_PREFIX + "/profile");
export const API_MAJOR_PREFIX: string = join(API_PREFIX + "/major");
export const API_SUBJECT_PREFIX: string = join(API_PREFIX + "/subject");
export const API_STUDENT_PREFIX: string = join(API_PREFIX + "/student");
export const API_SEMESTER_PREFIX: string = join(API_PREFIX + "/semester");
export const API_COURSE_SECTION_PREFIX: string = join(
  API_PREFIX + "/course-section"
);
export const API_ROLL_CALL_PREFIX: string = join(API_PREFIX + "/roll-call");
export const API_REPORT_PREFIX: string = join(API_PREFIX + "/report");
