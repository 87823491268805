import { useRef, useCallback, ChangeEvent } from "react";
import { Model, ModelFilter } from "react3l-common";
import { RefObject } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { Observable } from "rxjs";
import { saveAs } from "file-saver";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { webService } from "../common-services/web-service";
import { generalLanguageKeys } from "config/language-keys";
import React from "react";
import _ from "lodash";
import { finalize } from "rxjs/operators";

export const importExportService = {
  /**
   *
   * react hook for handle action import file
   * @param: dispatch
   * @param: onImportSuccess?: (list?: T[]) => void,
   * @return: { ref, handleClick, handleImportList, handleImportContentList }
   *
   * */

  useImport<T extends Model>(onImportSuccess?: (list?: T[]) => void) {
    const [subscription] = webService.useSubscription(); // subscription avoid leak memory
    const [translate] = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);

    const ref: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null); // ref object to clear value of input after import

    function convertErrorStringToArray(errorString) {
      // Sử dụng split theo "Dòng", sau đó bỏ phần tử trống
      const parts = errorString
        .split(/(Dòng \d+)/g)
        .filter((item) => item?.trim() !== "");
      let resultArray = [];
      if (parts && parts?.length > 1) {
        for (let i = 0; i < parts.length; i += 2) {
          // Ghép các phần "Dòng" và lỗi của nó lại với nhau
          let line = parts[i].trim(); // Phần "Dòng X"
          let error = parts[i + 1].trim(); // Phần lỗi tương ứng
          resultArray.push(`${line} ${error}`);
        }
      }

      return resultArray;
    }

    /** handle action when import get error **/
    const handleImportError = useCallback(
      (error: AxiosError<any>) => {
        const convertError = convertErrorStringToArray(error.response.data);
        function content() {
          if (error.response.data) {
            if (
              _.isArray(error.response.data) &&
              error.response.data?.length > 0
            ) {
              return error.response.data.map((item: any, index: number) => {
                return <div key={"error" + index}>{item}</div>;
              });
            } else if (_.isArray(convertError) && convertError?.length > 0) {
              return convertError.map((item: any, index: number) => {
                return <div key={"error" + index}>{item}</div>;
              });
            } else {
              return error.response.data;
            }
          } else {
            return "";
          }
        }

        Modal.error({
          title: (
            <>
              <div>{translate(generalLanguageKeys.toasts.error)}</div>
              <div>{translate(generalLanguageKeys.toasts.warning)}</div>
            </>
          ),
          content: content(),
          className: "ant-modal-import-error",
        });
      },
      [translate]
    );

    /** handle import list from server **/
    const handleImportList = useCallback(
      (onImport: (file: File) => Observable<void>) => {
        return (event: ChangeEvent<HTMLInputElement>) => {
          if (event.target.files.length > 0) {
            const file: File = event.target.files[0];
            if (typeof onImport === "function") {
              setLoading(true);
              subscription.add(
                onImport(file)
                  .pipe(finalize(() => setLoading(false)))
                  .subscribe({
                    next: (_res: any) => {
                      Modal.success({
                        content: translate(generalLanguageKeys.toasts.success),
                      });
                      if (typeof onImportSuccess === "function") {
                        onImportSuccess();
                      }
                    }, // onSuccess
                    error: (err: any) => handleImportError(err),
                  })
              );
            }
          }
        };
      },
      [subscription, handleImportError, onImportSuccess, translate]
    );

    /** handle import content list from server **/
    const handleImportContentList = useCallback(
      (
        modelId: number,
        onImport: (file: File, priceListId: number) => Observable<T[]>
      ) => {
        return (event: ChangeEvent<HTMLInputElement>) => {
          const file: File = event.target.files[0];
          if (typeof onImport === "function") {
            setLoading(true);
            subscription.add(
              onImport(file, modelId)
                .pipe(finalize(() => setLoading(false)))
                .subscribe({
                  next: (list: T[]) => {
                    Modal.success({
                      content: translate(generalLanguageKeys.toasts.success),
                    });
                    if (typeof onImportSuccess === "function") {
                      onImportSuccess(list);
                    }
                  },
                  error: handleImportError, // onError
                })
            );
          }
        };
      },
      [handleImportError, onImportSuccess, subscription, translate]
    );

    const handleClick = useCallback(() => {
      ref.current.value = null;
    }, []);

    return {
      loading,
      ref,
      handleClick,
      handleImportList,
      handleImportError,
      handleImportContentList,
    };
  },

  /**
   *
   * react hook for handle action import file
   * @return: { handleListExport,
      handleExportTemplateList,
      handleContentExport,
      handleContentExportTemplate }
   *
   * */
  useExport() {
    const [subscription] = webService.useSubscription();
    const [loading, setLoading] = React.useState<boolean>(false);

    /** handle action when export succesfully **/
    const handleExportSuccess = (response: AxiosResponse<any>) => {
      const fileName = response.headers["content-disposition"]
        .split(";")
        .find((n: any) => n.includes("filename="))
        .replace("filename=", "")
        .trim(); // define fileName for saver
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/octet-stream",
        })
      ); // defince urlObject
      setLoading(false);
      saveAs(url, fileName); // save file
    };

    /** handle export list from server **/
    const handleListExport = useCallback(
      <TFilter extends ModelFilter>(
        filter: TFilter,
        onExport: (filter: ModelFilter) => Observable<AxiosResponse<any>>
      ) => {
        return () => {
          if (typeof onExport === "function") {
            setLoading(true);
            subscription.add(
              onExport(filter).subscribe(
                handleExportSuccess // onSuccess
              )
            );
          }
        };
      },
      [subscription]
    );

    /** handle export template from server **/
    const handleExportTemplateList = useCallback(
      (onExport: () => Observable<AxiosResponse<any>>) => {
        return () => {
          if (typeof onExport === "function") {
            subscription.add(
              onExport().subscribe(
                handleExportSuccess // onSuccess
              )
            );
          }
        };
      },
      [subscription]
    );

    /** handleExport contentList from server  **/
    const handleContentExport = useCallback(
      <T extends Model>(
        model: T,
        onExport: (model: T) => Observable<AxiosResponse<any>>
      ) => {
        return () => {
          if (typeof onExport === "function") {
            subscription.add(
              onExport(model).subscribe(
                handleExportSuccess // onSuccess
              )
            );
          }
        };
      },
      [subscription]
    );

    /** handleExport listContent template from server **/
    const handleContentExportTemplate = useCallback(
      <T extends Model>(
        model: T,
        onExport: (id: number) => Observable<AxiosResponse<any>>
      ) => {
        return () => {
          if (typeof onExport === "function") {
            subscription.add(
              onExport(model?.id).subscribe(
                handleExportSuccess // onSuccess
              )
            );
          }
        };
      },
      [subscription]
    );

    return {
      handleListExport,
      handleExportTemplateList,
      handleContentExport,
      handleContentExportTemplate,
      loading,
    };
  },
};
