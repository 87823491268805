import { AppUser } from "models/AppUser";
import { CourseSectionAppUserMapping } from "models/CourseSectionAppUserMapping";
import { CourseSectionShift } from "models/CourseSectionShift";
import { CourseSectionStudentMapping } from "models/CourseSectionStudentMapping";
import { RollCall } from "models/RollCall";
import { Semester } from "models/Semester";
import { Status } from "models/Status";
import { Subject } from "models/Subject";
import type { Moment } from "moment";
import { Model } from "react3l-common";
import { Field, MomentField } from "react3l-decorators";

export class CourseSection extends Model {
  @Field(Number)
  public id?: number;

  @Field(String)
  public code?: string;

  @Field(Number)
  public statusId?: number;

  @Field(Number)
  public semesterId?: number;

  @Field(Number)
  public subjectId?: number;

  @Field(Number)
  public appUserId?: number;

  @Field(String)
  public name?: string;

  @Field(Boolean)
  public isStartSameSemester?: boolean;

  @MomentField()
  public startedAt?: Moment;

  @MomentField()
  public endedAt?: Moment;

  @Field(Number)
  public numberOfCreditHours?: number;

  @Field(Number)
  public numberStudent?: number;

  public semester?: Semester;

  public subject?: Subject;

  public status?: Status;

  public courseSectionAppUserMappings?: CourseSectionAppUserMapping[];

  public courseSectionShifts?: CourseSectionShift[];

  public rollCallContents?: RollCall[];

  public courseSectionStudentMappings?: CourseSectionStudentMapping[];
}
