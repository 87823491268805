import Login from "pages/Authentication/Login/Login";
import Logout from "pages/Authentication/Logout";
import ForbidentView from "pages/ForbidentView/ForbidentView";
import { Redirect } from "react-router";
import AppUserView from "views/AppUserView/AppUserView";
import {
  APP_USER_MASTER_ROUTE,
  APP_USER_ROUTE,
  COURSE_SECTION_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  MAJOR_ROUTE,
  REPORT_BY_COURSE_SECTION_ROUTE,
  REPORT_BY_MAJOR_ROUTE,
  REPORT_BY_STUDENT_ROUTE,
  REPORT_NOT_ROLL_CALL_ROUTE,
  REPORT_ROLL_CALL_DETAIL_ROUTE,
  REPORT_ROUTE,
  ROLL_CALL_MASTER_ROUTE,
  ROLL_CALL_ROUTE,
  SEMESTER_ROUTE,
  STUDENT_ROUTE,
  SUBJECT_ROUTE,
} from "./route-consts";
import SubjectView from "views/Subject/SubjectView";
import MajorView from "views/Major/MajorView";
import StudentView from "views/Student/StudentView";
import SemesterView from "views/Semester/SemesterView";
import CourseSectionView from "views/CourseSection/CourseSectionView";
import RollCallView from "views/RollCall/RollCallView";
import ReportByCourseSectionView from "views/ReportByCourseSection/ReportByCourseSectionView";
import ReportRollCallDetailView from "views/ReportRollCallDetail/ReportRollCallDetailView";
import ReportNotRollCallView from "views/ReportNotRollCall/ReportNotRollCallView";
import ReportByStudentView from "views/ReportByStudent/ReportByStudentView";
import ReportByMajorView from "views/ReportByMajor/ReportByMajorView";

export interface Route {
  path: string;
  component: () => JSX.Element;
  exact?: boolean;
}

const userRoutes: Route[] = [
  // Default init route for template:

  {
    path: APP_USER_ROUTE,
    component: AppUserView,
  },

  {
    path: MAJOR_ROUTE,
    component: MajorView,
  },

  {
    path: SUBJECT_ROUTE,
    component: SubjectView,
  },

  {
    path: STUDENT_ROUTE,
    component: StudentView,
  },

  {
    path: SEMESTER_ROUTE,
    component: SemesterView,
  },

  {
    path: COURSE_SECTION_ROUTE,
    component: CourseSectionView,
  },

  {
    path: ROLL_CALL_ROUTE,
    component: RollCallView,
  },
  {
    path: REPORT_BY_COURSE_SECTION_ROUTE,
    component: ReportByCourseSectionView,
  },
  {
    path: REPORT_BY_STUDENT_ROUTE,
    component: ReportByStudentView,
  },
  {
    path: REPORT_BY_MAJOR_ROUTE,
    component: ReportByMajorView,
  },
  {
    path: REPORT_ROLL_CALL_DETAIL_ROUTE,
    component: ReportRollCallDetailView,
  },
  {
    path: REPORT_NOT_ROLL_CALL_ROUTE,
    component: ReportNotRollCallView,
  },
  // this base route should be at the end of all other routes
  {
    path: `${process.env.PUBLIC_URL}/`,
    exact: true,
    component: () => <Redirect to={ROLL_CALL_MASTER_ROUTE} />,
  },
];

const authRoutes = [
  { path: LOGOUT_ROUTE, component: Logout },
  { path: LOGIN_ROUTE, component: Login },
  {
    path: "/403",
    // exact: true,
    component: ForbidentView,
  },
];

export { userRoutes, authRoutes };
