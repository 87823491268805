/* begin general import */
import { AppUser } from "models/AppUser";
import React, { useState } from "react";
import { Drawer, FormItem } from "react3l-ui-library";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { View16, ViewOff16 } from "@carbon/icons-react";
import { ModelAction } from "services/page-services/detail-service";
import { DrawerProps } from "react3l-ui-library/build/components/Drawer/Drawer";
import nameof from "ts-nameof.macro";
import {
  utilService,
  ValidateStatus,
} from "services/common-services/util-service";
import { ASSETS_SVG } from "config/consts";
import { InputText } from "components/InputText";
/* end individual import */

interface ChangePassProps extends DrawerProps {
  model: AppUser;
  handleChangeSingleField?: (config: {
    fieldName: string;
  }) => (value: any) => void;
  dispatch?: React.Dispatch<ModelAction<AppUser>>;
  loading?: boolean;
  isAdminChangePassword?: boolean;
}

export function ChangePass(props: ChangePassProps) {
  const [translate] = useTranslation();

  const {
    model,
    loading,
    visible,
    isAdminChangePassword,
    handleSave,
    handleCancel,
    handleChangeSingleField,
  } = props;

  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] =
    useState<boolean>(false);

  const toggleShowOldPassword = React.useCallback(() => {
    setShowOldPassword(!showOldPassword);
  }, [showOldPassword]);

  const toggleShowNewPassword = React.useCallback(() => {
    setShowNewPassword(!showNewPassword);
  }, [showNewPassword]);

  const toggleShowConfirmNewPassword = React.useCallback(() => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  }, [showConfirmNewPassword]);

  const isConfirmPasswordValid = React.useMemo(() => {
    if (model?.confirmNewPassword && model?.confirmNewPassword !== "") {
      return model?.newPassword === model?.confirmNewPassword;
    } else return true;
  }, [model?.confirmNewPassword, model?.newPassword]);

  const disableButton = React.useMemo(() => {
    if (model?.confirmNewPassword && model?.confirmNewPassword !== "") {
      return !(model?.newPassword === model?.confirmNewPassword);
    } else return true;
  }, [model?.confirmNewPassword, model?.newPassword]);

  return (
    <Drawer
      {...props}
      loading={loading}
      visible={visible}
      handleSave={handleSave}
      handleCancel={handleCancel}
      handleClose={handleCancel}
      visibleFooter={true}
      title={translate("appUsers.changePassword")}
      titleButtonCancel={translate("general.actions.close")}
      titleButtonApply={translate("general.actions.save")}
      disableButton={disableButton}
    >
      {loading ? (
        <div className="loading-block">
          <img src={ASSETS_SVG + "/spinner.svg"} alt="Loading..." />
        </div>
      ) : (
        <div className="page page__detail">
          <div className="w-100 page__detail-tabs">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {isAdminChangePassword ? (
                <></>
              ) : (
                <Col lg={24} className="m-b--sm">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.oldPassword)
                    )}
                    message={model.errors?.oldPassword}
                  >
                    <InputText
                      typeInput={showOldPassword ? "text" : "password"}
                      label={translate("appUsers.oldPassword")}
                      suffix={
                        <button
                          type="button"
                          role="link"
                          className="btn btn-link"
                          onClick={toggleShowOldPassword}
                        >
                          {showOldPassword ? <ViewOff16 /> : <View16 />}
                        </button>
                      }
                      value={model?.oldPassword}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.oldPassword),
                      })}
                      placeHolder={translate(
                        "appUsers.placeholder.oldPassword"
                      )}
                      type={0}
                      isRequired
                      nameAttr="pass"
                    />
                  </FormItem>
                </Col>
              )}

              <Col lg={24} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.NewPassword)
                  )}
                  message={model.errors?.NewPassword}
                >
                  <InputText
                    typeInput={showNewPassword ? "text" : "password"}
                    label={translate("appUsers.newPassword")}
                    suffix={
                      <button
                        type="button"
                        role="link"
                        className="btn btn-link"
                        onClick={toggleShowNewPassword}
                      >
                        {showNewPassword ? <ViewOff16 /> : <View16 />}
                      </button>
                    }
                    value={model?.newPassword}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.newPassword),
                    })}
                    placeHolder={translate("appUsers.placeholder.newPassword")}
                    type={0}
                    isRequired
                    nameAttr="pass"
                  />
                </FormItem>
              </Col>

              <Col lg={24} className="m-b--sm">
                <FormItem
                  validateStatus={
                    isConfirmPasswordValid ? undefined : ValidateStatus.error
                  }
                  message={
                    isConfirmPasswordValid
                      ? ""
                      : translate("appUsers.passwordNotSame")
                  }
                >
                  <InputText
                    typeInput={showConfirmNewPassword ? "text" : "password"}
                    label={translate("appUsers.confirmNewPassword")}
                    suffix={
                      <button
                        type="button"
                        role="link"
                        className="btn btn-link"
                        onClick={toggleShowConfirmNewPassword}
                      >
                        {showConfirmNewPassword ? <ViewOff16 /> : <View16 />}
                      </button>
                    }
                    value={model?.confirmNewPassword}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.confirmNewPassword),
                    })}
                    placeHolder={translate("appUsers.confirmNewPassword")}
                    type={0}
                    isRequired
                    nameAttr="pass"
                  />
                </FormItem>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Drawer>
  );
}

export default ChangePass;
