/* begin general import */

import { SettingsAdjust16, Task20 } from "@carbon/icons-react";
import { PaginationProps, Spin, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import PageHeader from "components/PageHeader/PageHeader";
import { ROLL_CALL_DETAIL_ROUTE } from "config/route-consts";
import { RollCall, RollCallFilter } from "models/RollCall";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AdvanceIdFilterMaster,
  Button,
  OneLineText,
  Pagination,
  StandardTable,
  TagFilter,
} from "react3l-ui-library";
import { filterService } from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { queryStringService } from "services/page-services/query-string-service";
import {
  getAntOrderType,
  tableService,
} from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import RollCallAdvanceFilter from "./RollCallAdvanceFilter";
import { IdFilter } from "react3l-advanced-filters";
import { renderMasterIndex } from "helpers/table";
import InputSearch from "components/InputSearch";
import { Subject, SubjectFilter } from "models/Subject";
import { AppUser, AppUserFilter } from "models/AppUser";
import { Semester, SemesterFilter } from "models/Semester";
import { formatNumber } from "helpers/number";
import { rollCallRepository } from "repositories/roll-call-repository";
import { CourseSectionAppUserMapping } from "models/CourseSectionAppUserMapping";
import { CourseSectionShift } from "models/CourseSectionShift";
import { useHistory } from "react-router";
import LayoutCell from "components/LayoutCell";
import LayoutHeader from "components/LayoutHeader";
import TruncateCell from "components/TruncateCell";
/* end individual import */

function RollCallMaster() {
  const [translate] = useTranslation();
  const [modelFilter, dispatch] = queryStringService.useQueryString(
    RollCallFilter,
    { skip: 0, take: 10 }
  );

  const {
    value: filter,
    handleChangeAllFilter,
    handleChangeSelectFilter,
    handleChangeInputSearch,
  } = filterService.useFilter(modelFilter, dispatch);

  const { list, count, loadingList, handleResetList, handleLoadList } =
    listService.useList(
      rollCallRepository.listCourseSection,
      rollCallRepository.countCourseSection,
      filter,
      dispatch
    );

  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeAllFilter
  );

  const history = useHistory();

  const handleGoRollCallCourseSection = React.useCallback(
    (courseSectionId: number) => {
      return () => {
        history.push(
          `${ROLL_CALL_DETAIL_ROUTE}?courseSectionId=${courseSectionId}`
        );
      };
    },
    [history]
  );

  const [visible, setVisible] = React.useState<boolean>(false);

  const pagination: PaginationProps = tableService.usePagination(filter, count);

  const columns: ColumnProps<RollCall>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
            position="center"
          />
        ),
        key: "index",
        width: 60,
        render(...params: [number, RollCall, number]) {
          const index = renderMasterIndex<RollCall>(pagination)(...params);
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={formatNumber(index)} />
            </LayoutCell>
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "code"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("courseSections.code")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].code),
        dataIndex: nameof(list[0].code),
        sorter: true,
        sortOrder: getAntOrderType<RollCall, RollCallFilter>(
          filter,
          nameof(list[0].code)
        ),
        width: "12%",
        render(...params: [string, RollCall, number]) {
          return (
            <TruncateCell
              renderContent={params[0]}
              className="text-link text-decoration-underline"
              onClick={handleGoRollCallCourseSection(params[1]?.id)}
            />
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "subject"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("courseSections.subject")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        width: "15%",
        key: nameof(list[0].subject),
        dataIndex: nameof(list[0].subject),
        sorter: true,
        sortOrder: getAntOrderType<RollCall, RollCallFilter>(
          filter,
          nameof(list[0].subject)
        ),
        render(...params: [Subject, RollCall, number]) {
          return <TruncateCell renderContent={params[0]?.name} />;
        },
      },
      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("courseSections.courseSectionAppUserMappings")}
            />
          );
        },
        width: "18%",
        key: nameof(list[0].courseSectionAppUserMappings),
        dataIndex: nameof(list[0].courseSectionAppUserMappings),
        render(...params: [CourseSectionAppUserMapping[], RollCall, number]) {
          const listCourseSectionAppUserName = params[0]
            ? params[0]?.map(
                (courseSectionAppUser: CourseSectionAppUserMapping) =>
                  courseSectionAppUser?.appUser?.displayName
              )
            : [];
          return (
            <TruncateCell
              renderContent={listCourseSectionAppUserName.join(", ")}
            />
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "semester"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("courseSections.semester")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].semester),
        dataIndex: nameof(list[0].semester),
        sorter: true,
        sortOrder: getAntOrderType<RollCall, RollCallFilter>(
          filter,
          nameof(list[0].semester)
        ),
        width: 150,
        render(...params: [Semester, RollCall, number]) {
          return <TruncateCell renderContent={params[0]?.name} />;
        },
      },
      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("courseSections.courseSectionShifts")}
            />
          );
        },
        key: nameof(list[0].courseSectionShifts),
        dataIndex: nameof(list[0].courseSectionShifts),
        render(...params: [CourseSectionShift[], RollCall, number]) {
          const listCourseSectionShiftName = params[0]
            ? params[0]?.map((courseSectionShift: CourseSectionShift) => {
                const classShift = courseSectionShift?.weekday
                  ? `${courseSectionShift?.weekday?.name} - ${courseSectionShift?.classShift?.name} - ${courseSectionShift?.room}`
                  : `${courseSectionShift?.classShift?.name} - ${courseSectionShift?.room}`;
                return classShift;
              })
            : [];
          return (
            <TruncateCell
              renderContent={listCourseSectionShiftName.join(", ")}
            />
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "numberStudent"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("courseSections.numberStudent")}
              sortedColumn={sortedColumn}
              isSorter
              position="right"
            />
          );
        },
        key: nameof(list[0].numberStudent),
        dataIndex: nameof(list[0].numberStudent),
        sorter: true,
        sortOrder: getAntOrderType<RollCall, RollCallFilter>(
          filter,
          nameof(list[0].numberStudent)
        ),
        width: 150,
        render(...params: [number, RollCall, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md" position="right">
              <OneLineText value={formatNumber(params[0])} />
            </LayoutCell>
          );
        },
      },

      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("general.actions.label")}
            />
          );
        },
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 80,
        align: "center",
        render(id: number, rollCall: RollCall) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Tooltip title={translate("rollCalls.actions.rollCall")}>
                <div className="enable-dxg">
                  <Task20
                    color="#24a148"
                    onClick={handleGoRollCallCourseSection(id)}
                    className="btn-missions-blue"
                  />
                </div>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [translate, pagination, list, filter, handleGoRollCallCourseSection]
  );

  const handleCancel = React.useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <>
      <Spin spinning={loadingList}>
        <div className="page-content">
          <PageHeader
            title={translate("rollCalls.master.subHeader")}
            breadcrumbItems={[
              translate("rollCalls.master.header"),
              translate("rollCalls.master.subHeader"),
            ]}
          />

          <div className="page page-master m-l--sm m-r--xxl m-b--xxs">
            <div className="page-master__title p-l--sm p-t--xs p-b--xs">
              {translate("rollCalls.master.subHeader")}
            </div>
            <div className="page-master__content">
              <div className="page-master__tag-filter">
                <TagFilter
                  value={filter}
                  translate={translate}
                  keyTranslate={"rollCalls"}
                  handleChangeFilter={handleChangeAllFilter}
                  onClear={() => {
                    return 0;
                  }}
                />
              </div>
              <div className="page-master__filter-wrapper d-flex align-items-center justify-content-between">
                <div className="page-master__filter d-flex align-items-center justify-content-start">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <AdvanceIdFilterMaster
                        value={filter[nameof(list[0].semesterId)]["equal"]}
                        placeHolder={translate(
                          "rollCalls.placeholder.semester"
                        )}
                        classFilter={SemesterFilter}
                        onChange={handleChangeSelectFilter({
                          fieldName: nameof(list[0].semester),
                          fieldType: "equal",
                          classFilter: IdFilter,
                        })}
                        getList={rollCallRepository.filterListSemester}
                        title={translate("rollCalls.semester")}
                      />
                    </div>
                    <div className="">
                      <AdvanceIdFilterMaster
                        value={filter[nameof(list[0].subjectId)]["equal"]}
                        placeHolder={translate("rollCalls.placeholder.subject")}
                        classFilter={SubjectFilter}
                        onChange={handleChangeSelectFilter({
                          fieldName: nameof(list[0].subject),
                          fieldType: "equal",
                          classFilter: IdFilter,
                        })}
                        getList={rollCallRepository.filterListSubject}
                        title={translate("rollCalls.subject")}
                      />
                    </div>
                    <div className="">
                      <AdvanceIdFilterMaster
                        value={filter[nameof(list[0].appUserId)]["equal"]}
                        placeHolder={translate("rollCalls.placeholder.appUser")}
                        classFilter={AppUserFilter}
                        onChange={(id, value) => {
                          value.name = value?.displayName;
                          handleChangeSelectFilter({
                            fieldName: nameof(list[0].appUser),
                            fieldType: "equal",
                            classFilter: IdFilter,
                          })(id, value);
                        }}
                        getList={rollCallRepository.filterListAppUser}
                        title={translate("rollCalls.appUser")}
                        render={(appUser: AppUser) => appUser?.displayName}
                      />
                    </div>
                  </div>
                </div>
                <div className="page-master__filter-action-search d-flex align-items-center">
                  <Button
                    type="icon-only-ghost"
                    icon={<SettingsAdjust16 />}
                    onClick={() => setVisible(true)}
                    className="btn--xl"
                  />
                  <InputSearch
                    value={filter?.search}
                    classFilter={RollCallFilter}
                    placeHolder={translate("general.placeholder.search")}
                    onChange={handleChangeInputSearch}
                  />
                </div>

                <div className="page-master__actions  d-flex align-items-center justify-content-start">
                  <div className="page-master__filter-action d-flex align-items-center"></div>
                </div>
              </div>
            </div>
            <div className="page-master__content-table">
              <StandardTable
                rowKey={nameof(list[0].id)}
                columns={columns}
                dataSource={list}
                isDragable={true}
                tableSize={"md"}
                onChange={handleTableChange}
                spinning={loadingList}
                bordered
                scroll={{ y: 500 }}
              />

              <Pagination
                skip={filter?.skip}
                take={filter?.take}
                total={count}
                onChange={handlePagination}
              />
            </div>
          </div>
        </div>
      </Spin>

      {visible && (
        <RollCallAdvanceFilter
          visible={visible}
          handleClose={handleCancel}
          visibleFooter={true}
          loading={false}
          size={"sm"}
          filter={filter}
          setVisible={setVisible}
          handleChangeAllFilter={handleChangeAllFilter}
        />
      )}
    </>
  );
}
export default RollCallMaster;
