import { Dropdown, Menu, PaginationProps } from "antd";
import { ColumnProps } from "antd/lib/table";
import { CourseSectionFilter } from "models/CourseSection";
import React, { Reducer } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  OneLineText,
  Pagination,
  StandardTable,
} from "react3l-ui-library";
import {
  FilterAction,
  filterReducer,
  filterService,
} from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import nameof from "ts-nameof.macro";
import { renderMasterIndex } from "helpers/table";
import _ from "lodash";
import { courseSectionRepository } from "repositories/course-section-repository";
import { tableService } from "services/page-services/table-service";
import { masterService } from "services/page-services/master-service";
import {
  COURSE_SECTION_ROLL_CALL_DETAIL_ROUTE,
  COURSE_SECTION_ROUTE,
} from "config/route-consts";
import { AppUser } from "models/AppUser";
import { Moment } from "moment";
import { RollCall } from "models/RollCall";
import { formatDate, formatDateTime } from "helpers/date-time";
import { formatNumber } from "helpers/number";
import { Add16, OverflowMenuHorizontal24 } from "@carbon/icons-react";
import { useHistory } from "react-router";
import { rollCallRepository } from "repositories/roll-call-repository";
import LayoutHeader from "components/LayoutHeader";
import LayoutCell from "components/LayoutCell";

export function RollCallTable(props: { courseSectionId: number }) {
  const { courseSectionId } = props;
  const [translate] = useTranslation();
  const history = useHistory();
  const [modelFilter, dispatch] = React.useReducer<
    Reducer<CourseSectionFilter, FilterAction<CourseSectionFilter>>
  >(filterReducer, {
    ...new CourseSectionFilter(),
    courseSectionId: { equal: courseSectionId },
    skip: 0,
    take: 10,
  });

  const currentUserInfo = JSON.parse(localStorage.getItem("currentUserInfo"));

  const { value: filter, handleChangeAllFilter } = filterService.useFilter(
    modelFilter,
    dispatch
  );

  const { list, count, loadingList, handleResetList } = listService.useList(
    rollCallRepository.list,
    rollCallRepository.count,
    filter,
    dispatch
  );

  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeAllFilter
  );

  const { handleAction } = listService.useRowSelection(
    courseSectionRepository.deleteRollCall,
    courseSectionRepository.bulkDelete,
    null,
    null,
    null,
    handleResetList
  );

  const { handleDeleteItem } = masterService.useMasterAction(
    COURSE_SECTION_ROUTE,
    handleAction
  );

  const handleGoRollCall = React.useCallback(
    (rollCallId?: number) => {
      if (rollCallId) {
        return () => {
          history.push(
            `${COURSE_SECTION_ROLL_CALL_DETAIL_ROUTE}?courseSectionId=${courseSectionId}&&rollCallId=${rollCallId}`
          );
        };
      } else {
        return () => {
          history.push(
            `${COURSE_SECTION_ROLL_CALL_DETAIL_ROUTE}?courseSectionId=${courseSectionId}`
          );
        };
      }
    },
    [courseSectionId, history]
  );

  const handleReRollCall = React.useCallback(
    (rollCallId?: number) => {
      return () => {
        history.push(
          `${COURSE_SECTION_ROLL_CALL_DETAIL_ROUTE}?courseSectionId=${courseSectionId}&&rollCallId=${rollCallId}&&isClone=${true}`
        );
      };
    },
    [courseSectionId, history]
  );

  const menuAction = React.useCallback(
    (id: number, rollCall: RollCall) => (
      <Menu>
        {currentUserInfo?.id === rollCall?.appUserId ? (
          <Menu.Item key="1">
            <div
              className="ant-action-menu text-center"
              onClick={handleGoRollCall(id)}
            >
              {translate("general.actions.edit")}
            </div>
          </Menu.Item>
        ) : (
          <></>
        )}

        <Menu.Item key="2">
          <div
            className="ant-action-menu text-center"
            onClick={handleReRollCall(id)}
          >
            {translate("general.actions.reRollCall")}
          </div>
        </Menu.Item>
        {currentUserInfo?.roleType?.code === "MANAGER" ? (
          <Menu.Item key="3">
            <div
              className="ant-action-menu text-center"
              onClick={handleDeleteItem(rollCall)}
            >
              {translate("general.actions.delete")}
            </div>
          </Menu.Item>
        ) : (
          <></>
        )}
      </Menu>
    ),
    [
      currentUserInfo?.id,
      currentUserInfo?.roleType?.code,
      handleGoRollCall,
      translate,
      handleReRollCall,
      handleDeleteItem,
    ]
  );

  const pagination: PaginationProps = tableService.usePagination(filter, count);

  const columns: ColumnProps<RollCall>[] = React.useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
            position="center"
          />
        ),
        key: "index",
        width: 60,
        render(...params: [number, RollCall, number]) {
          const index = renderMasterIndex<RollCall>(pagination)(...params);
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={formatNumber(index)} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("courseSections.courseSectionRollCalls.appUser")}
          />
        ),
        key: "appUser",
        dataIndex: "appUser",
        render(...params: [AppUser, RollCall, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText
                value={
                  params[0]?.roleType
                    ? `${params[0]?.displayName} - ${params[0]?.roleType?.name}`
                    : params[0]?.displayName
                }
                countCharacters={35}
              />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate(
              "courseSections.courseSectionRollCalls.lessonDate"
            )}
            position="center"
          />
        ),
        key: "lessonDate",
        dataIndex: "lessonDate",
        width: 200,
        render(...params: [Moment, RollCall, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={params[0] ? formatDate(params[0]) : ""} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("courseSections.courseSectionRollCalls.checkinAt")}
            position="center"
          />
        ),
        key: "checkinAt",
        dataIndex: "checkinAt",
        width: 200,
        render(...params: [Moment, RollCall, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText
                value={
                  params[0] ? formatDateTime(params[0], "DD-MM-YYYY HH:mm") : ""
                }
              />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate(
              "courseSections.courseSectionRollCalls.indexOfLesson"
            )}
            position="right"
          />
        ),
        key: "indexOfLesson",
        dataIndex: "indexOfLesson",
        width: 250,
        render(...params: [number, RollCall, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md" position="right">
              <OneLineText value={formatNumber(params[0])} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate(
              "courseSections.courseSectionRollCalls.numberOfInClassStudent"
            )}
            position="right"
          />
        ),
        key: "numberOfInClassStudent",
        dataIndex: "numberOfInClassStudent",
        width: 250,
        render(...params: [number, RollCall, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md" position="right">
              <OneLineText
                value={`${params[0]}/${params[1]?.numberOfStudent}`}
                countCharacters={35}
              />
            </LayoutCell>
          );
        },
      },

      {
        title: translate("general.actions.label"),
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 80,
        align: "center",
        render(id: number, rollCall: RollCall) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id, rollCall)}
                trigger={["click"]}
                placement="bottom"
                arrow
              >
                <OverflowMenuHorizontal24 />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [list, menuAction, pagination, translate]
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="page-detail__title">
          {translate("courseSections.detail.rollCallTitle")}
        </div>

        <Button
          type="primary"
          className="btn--lg btn-orange-export ml-3"
          icon={<Add16 />}
          onClick={handleGoRollCall()}
        >
          {translate("courseSections.courseSectionRollCalls.rollCall")}
        </Button>
      </div>
      <StandardTable
        rowKey={nameof(list[0].id)}
        columns={columns}
        dataSource={list}
        isDragable={true}
        tableSize={"md"}
        onChange={handleTableChange}
        spinning={loadingList}
        scroll={{ y: 500 }}
        bordered
      />

      <Pagination
        skip={filter?.skip}
        take={filter?.take}
        total={count}
        onChange={handlePagination}
      />
    </>
  );
}
