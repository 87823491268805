import { REPORT_BY_MAJOR_ROUTE } from "config/route-consts";
import { ProtectedRoute } from "pages/Authentication/ProtectedRoute";
import { Switch } from "react-router-dom";
import ReportByMajor from "./ReportByMajor";

function ReportByMajorView() {
  return (
    <Switch>
      <ProtectedRoute
        path={REPORT_BY_MAJOR_ROUTE}
        key={REPORT_BY_MAJOR_ROUTE}
        component={ReportByMajor}
        auth={true}
      />
    </Switch>
  );
}

export { ReportByMajorView };
export default ReportByMajorView;
