/* begin general import */
import Close16 from "@carbon/icons-react/es/close/16";
import Send16 from "@carbon/icons-react/es/send/16";
import { Col, Row, Spin, Switch } from "antd";
import { APP_USER_ROUTE } from "config/route-consts";
import { AppUser } from "models/AppUser";
import { Organization, OrganizationFilter } from "models/Organization";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Select } from "react3l-ui-library";
import FormItem from "react3l-ui-library/build/components/FormItem";
import InputText from "react3l-ui-library/build/components/Input/InputText";
import { appUserRepository } from "repositories/app-user-repository";
import { utilService } from "services/common-services/util-service";
import { detailService } from "services/page-services/detail-service";
import { fieldService } from "services/page-services/field-service";
import { InputText as InputTextPassword } from "components/InputText";
import nameof from "ts-nameof.macro";
import "./AppUserDetail.scss";
import { authorizationService } from "services/common-services/authorization-service";
import { API_APP_USER_PREFIX } from "config/api-consts";
import { profileRepository } from "repositories/profile-repository";
import { STATUS_ENUM } from "config/consts";
import { RoleTypeFilter } from "models/RoleType";
import { View16, ViewOff16 } from "@carbon/icons-react";

/* end individual import */

function AppUserDetail() {
  const [translate] = useTranslation();

  const { model, dispatch } = detailService.useModel<AppUser>(AppUser);

  const { isDetail } = detailService.useGetIsDetail<AppUser>(
    profileRepository.get,
    dispatch
  );

  const {
    handleChangeAllField,
    handleChangeSingleField,
    handleChangeSelectField,
  } = fieldService.useField(model, dispatch);

  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const toggleShowPassword = React.useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const { handleSaveModel, handleGoMaster, loading } =
    detailService.useActionsDetail<AppUser>(
      model,
      appUserRepository.save,
      handleChangeAllField,
      APP_USER_ROUTE
    );

  return (
    <Spin spinning={loading}>
      <div className="page-content page-content-dxg" id="page-content-dxg-id">
        <div className="info-component m-l--sm m-r--xxl mb-3">
          <span>{translate("appUsers.master.header")}</span>
          <span> &gt; </span>
          <span className="info-primary">
            {translate("appUsers.detail.title")}
          </span>
        </div>

        <div className="page page-detail">
          <h5 className="font-bold page-title m-l--sm m-t--sm">
            {translate("appUsers.detail.title")}
          </h5>
          <div className="page-detail__content">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {/* Tên hiển thị */}
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.displayName)
                  )}
                  message={model.errors?.displayName}
                >
                  <InputText
                    label={translate("appUsers.displayName")}
                    type={0}
                    value={model.displayName}
                    placeHolder={translate("appUsers.placeholder.displayName")}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.displayName),
                    })}
                    showCount
                    maxLength={255}
                  />
                </FormItem>
              </Col>
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.email)
                  )}
                  message={model.errors?.email}
                >
                  <InputText
                    label={translate("appUsers.email")}
                    type={0}
                    value={model.email}
                    placeHolder={translate("appUsers.placeholder.email")}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.email),
                    })}
                    isRequired
                    showCount
                    maxLength={255}
                  />
                </FormItem>
              </Col>
              {!model?.id && (
                <Col lg={6} className="m-b--sm">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.password)
                    )}
                    message={model.errors?.password}
                  >
                    <InputTextPassword
                      label={translate("appUsers.password")}
                      isRequired={true}
                      type={0}
                      value={model.password}
                      placeHolder={translate("appUsers.placeholder.password")}
                      className={"tio-account_square_outlined"}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.password),
                      })}
                      typeInput={showPassword ? "text" : "password"}
                      suffix={
                        <button
                          type="button"
                          role="link"
                          className="btn btn-link"
                          onClick={toggleShowPassword}
                        >
                          {showPassword ? <ViewOff16 /> : <View16 />}
                        </button>
                      }
                    />
                  </FormItem>
                </Col>
              )}
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.phone)
                  )}
                  message={model.errors?.phone}
                >
                  <InputText
                    label={translate("appUsers.phone")}
                    type={0}
                    value={model.phone}
                    placeHolder={translate("appUsers.placeholder.phone")}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.phone),
                    })}
                    showCount
                    maxLength={20}
                  />
                </FormItem>
              </Col>
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.roleType)
                  )}
                  message={model.errors?.roleType}
                >
                  <Select
                    label={translate("appUsers.roleType")}
                    type={0}
                    classFilter={RoleTypeFilter}
                    searchProperty={"displayName"}
                    placeHolder={translate("appUsers.placeholder.roleType")}
                    getList={appUserRepository.singleListRoleType}
                    onChange={handleChangeSelectField({
                      fieldName: nameof(model.roleType),
                    })}
                    value={model.roleType}
                    isRequired
                  />
                </FormItem>
              </Col>
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.organization)
                  )}
                  message={model.errors?.organization}
                >
                  <Select
                    label={translate("appUsers.organization")}
                    type={0}
                    classFilter={OrganizationFilter}
                    searchProperty={"displayName"}
                    placeHolder={translate("appUsers.placeholder.organization")}
                    getList={appUserRepository.singleListOrganization}
                    onChange={handleChangeSelectField({
                      fieldName: nameof(model.organization),
                    })}
                    render={(organization: Organization) => {
                      return organization?.code && organization?.name
                        ? `${organization?.code} - ${organization?.name}`
                        : organization?.code
                        ? organization?.code
                        : null;
                    }}
                    value={model.organization}
                    isRequired
                  />
                </FormItem>
              </Col>
              {/* Trạng thái */}
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.status)
                  )}
                  message={model.errors?.status}
                >
                  <div>
                    <div className="label-status">
                      {translate("appUsers.status")}
                    </div>
                    <div className="align-content-center h-100">
                      <Switch
                        checked={model.statusId === 1 ? true : false}
                        onChange={(checked: boolean) => {
                          handleChangeSingleField({
                            fieldName: "statusId",
                          })(checked ? 1 : 0);
                          handleChangeSingleField({
                            fieldName: "status",
                          })(checked ? STATUS_ENUM[1] : STATUS_ENUM[0]);
                        }}
                        className="switch_status"
                      />
                    </div>
                  </div>
                </FormItem>
              </Col>
            </Row>
          </div>
        </div>
        <footer className="app-footer">
          <div className="app-footer__full d-flex justify-content-end align-items-center">
            <div className="app-footer__actions d-flex justify-content-end">
              <Button
                type="secondary"
                className="btn--lg"
                icon={<Close16 />}
                onClick={handleGoMaster}
              >
                {translate("general.actions.close")}
              </Button>
              <Button
                type="primary"
                className="btn--lg"
                icon={<Send16 />}
                onClick={handleSaveModel}
              >
                {translate("general.actions.save")}
              </Button>
            </div>
          </div>
        </footer>
      </div>
    </Spin>
  );
}

export default AppUserDetail;
