/* begin general import */
import { AppUser, AppUserFilter } from "models/AppUser";
import { ClassShiftFilter } from "models/ClassShift";
import { RollCallFilter } from "models/RollCall";
import { SemesterFilter } from "models/Semester";
import { SubjectFilter } from "models/Subject";
import { WeekdayFilter } from "models/Weekday";
import React from "react";
import { useTranslation } from "react-i18next";
import { IdFilter, StringFilter } from "react3l-advanced-filters";
import { AdvanceIdFilter } from "react3l-ui-library";
import AdvanceStringFilter from "react3l-ui-library/build/components/AdvanceFilter/AdvanceStringFilter";
import Drawer, {
  DrawerProps,
} from "react3l-ui-library/build/components/Drawer/Drawer";
import { Reducer } from "redux";
import { rollCallRepository } from "repositories/roll-call-repository";
import {
  FilterAction,
  filterReducer,
  filterService,
} from "services/page-services/filter-service";

/* end individual import */

export interface RollCallFilterProps extends DrawerProps {
  filter?: any;
  setVisible?: any;
  handleChangeAllFilter?: (data: any) => void;
}

function RollCallAdvanceFilter(props: RollCallFilterProps) {
  const [translate] = useTranslation();

  const { visible, filter, setVisible, handleChangeAllFilter, handleClose } =
    props;

  const [modelFilter, setModelFilter] = React.useReducer<
    Reducer<RollCallFilter, FilterAction<RollCallFilter>>
  >(filterReducer, filter);

  const { handleChangeInputFilter, handleChangeSelectFilter } =
    filterService.useFilter(filter, setModelFilter);

  const handleSaveModelFilter = React.useCallback(() => {
    handleChangeAllFilter(modelFilter);
    setVisible(false);
  }, [handleChangeAllFilter, modelFilter, setVisible]);

  const handleClearModelFilter = React.useCallback(() => {
    setModelFilter({
      type: 0,
      payload: new RollCallFilter(),
    });
  }, [setModelFilter]);

  return (
    <Drawer
      visible={visible}
      handleSave={handleSaveModelFilter}
      handleCancel={handleClearModelFilter}
      handleClose={handleClose}
      visibleFooter={true}
      loading={false}
      size={"sm"}
      title={translate("general.drawer.filter")}
      titleButtonCancel={translate("general.actions.clear")}
      titleButtonApply={translate("general.actions.apply")}
    >
      <div className="d-flex m-t--sm">
        <AdvanceStringFilter
          label={translate("rollCalls.code")}
          maxLength={100}
          value={modelFilter["code"]["contain"]}
          onChange={handleChangeInputFilter({
            fieldName: "code",
            fieldType: "contain",
            classFilter: StringFilter,
          })}
          placeHolder={translate("rollCalls.placeholder.code")}
          showCount
        />
      </div>

      <div className="d-flex m-t--sm">
        <AdvanceIdFilter
          value={modelFilter["subjectValue"]}
          onChange={handleChangeSelectFilter({
            fieldName: "subject",
            fieldType: "equal",
            classFilter: IdFilter,
          })}
          classFilter={SubjectFilter}
          getList={rollCallRepository.filterListSubject}
          placeHolder={translate("rollCalls.placeholder.subject")}
          label={translate("rollCalls.subject")}
        />
      </div>

      <div className="d-flex m-t--sm">
        <AdvanceIdFilter
          value={modelFilter["appUserValue"]}
          onChange={(id, value) => {
            value.name = value?.displayName;
            handleChangeSelectFilter({
              fieldName: "appUser",
              fieldType: "equal",
              classFilter: IdFilter,
            })(id, value);
          }}
          classFilter={AppUserFilter}
          getList={rollCallRepository.filterListAppUser}
          placeHolder={translate("rollCalls.placeholder.appUser")}
          label={translate("rollCalls.appUser")}
          render={(appUser: AppUser) => appUser?.displayName}
        />
      </div>

      <div className="d-flex m-t--sm">
        <AdvanceIdFilter
          value={modelFilter["semesterValue"]}
          onChange={handleChangeSelectFilter({
            fieldName: "semester",
            fieldType: "equal",
            classFilter: IdFilter,
          })}
          classFilter={SemesterFilter}
          getList={rollCallRepository.filterListSemester}
          placeHolder={translate("rollCalls.placeholder.semester")}
          label={translate("rollCalls.semester")}
        />
      </div>

      <div className="d-flex m-t--sm">
        <AdvanceIdFilter
          value={modelFilter["classShiftValue"]}
          onChange={handleChangeSelectFilter({
            fieldName: "classShift",
            fieldType: "equal",
            classFilter: IdFilter,
          })}
          classFilter={ClassShiftFilter}
          getList={rollCallRepository.filterListClassShift}
          placeHolder={translate("rollCalls.placeholder.classShift")}
          label={translate("rollCalls.classShift")}
        />
      </div>

      <div className="d-flex m-t--sm">
        <AdvanceIdFilter
          value={modelFilter["weekdayValue"]}
          onChange={handleChangeSelectFilter({
            fieldName: "weekday",
            fieldType: "equal",
            classFilter: IdFilter,
          })}
          classFilter={WeekdayFilter}
          getList={rollCallRepository.filterListWeekday}
          placeHolder={translate("rollCalls.placeholder.weekday")}
          label={translate("rollCalls.weekday")}
        />
      </div>
    </Drawer>
  );
}

export default RollCallAdvanceFilter;
