import {
  DateFilter,
  GuidFilter,
  IdFilter,
  StringFilter,
} from "react3l-advanced-filters";
import { ModelFilter } from "react3l-common";

export class MajorFilter extends ModelFilter {
  public id?: IdFilter = new IdFilter();
  public statusId?: IdFilter = new IdFilter();
  public organizationId?: IdFilter = new IdFilter();
  public name?: StringFilter = new StringFilter();
  public code?: StringFilter = new StringFilter();
  public updatedAt?: DateFilter = new DateFilter();
  public rowId?: GuidFilter = new GuidFilter();
  public search?: string = null;
}
