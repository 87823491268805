/* begin general import */
import { Col, Row, Switch } from "antd";
import { ASSETS_SVG, STATUS_ENUM } from "config/consts";
import { AppUser } from "models/AppUser";
import { Organization, OrganizationFilter } from "models/Organization";
import { RoleTypeFilter } from "models/RoleType";
import React from "react";
import { useTranslation } from "react-i18next";
import { InputText, Select } from "react3l-ui-library";
import Drawer, {
  DrawerProps,
} from "react3l-ui-library/build/components/Drawer/Drawer";
import FormItem from "react3l-ui-library/build/components/FormItem";
import { InputText as InputTextPassword } from "components/InputText";
import { appUserRepository } from "repositories/app-user-repository";
import { utilService } from "services/common-services/util-service";
import { ModelAction } from "services/page-services/detail-service";
import nameof from "ts-nameof.macro";
import { View16, ViewOff16 } from "@carbon/icons-react";

/* end individual import */

interface AppUserDetailDrawerProps extends DrawerProps {
  model: AppUser;
  handleChangeSingleField?: (config: {
    fieldName: string;
  }) => (value: any) => void;
  handleChangeSelectField: (config: {
    fieldName: string;
  }) => (idValue: number, value: AppUser) => void;
  dispatch?: React.Dispatch<ModelAction<AppUser>>;
  loading?: boolean;
}

function AppUserDetailDrawer(props: AppUserDetailDrawerProps) {
  const [translate] = useTranslation();

  const {
    model,
    handleChangeSingleField,
    handleChangeSelectField,
    loading,
    visible,
    handleSave,
    handleCancel,
  } = props;
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const toggleShowPassword = React.useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <Drawer
      {...props}
      visible={visible}
      handleSave={handleSave}
      handleCancel={handleCancel}
      handleClose={handleCancel}
      visibleFooter={true}
      title={
        model?.id
          ? translate("appUsers.detail.title")
          : translate("appUsers.actions.create")
      }
      titleButtonCancel={translate("general.actions.cancel")}
      titleButtonApply={translate("general.actions.save")}
    >
      {loading ? (
        <div className="loading-block">
          <img src={ASSETS_SVG + "/spinner.svg"} alt="Loading..." />
        </div>
      ) : (
        <div className="page page__detail">
          <div className="w-100 page__detail-tabs">
            <Row className="d-flex">
              <Col lg={24}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col lg={24} className="m-b--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.displayName)
                      )}
                      message={model.errors?.displayName}
                    >
                      <InputText
                        label={translate("appUsers.displayName")}
                        type={0}
                        value={model.displayName}
                        placeHolder={translate(
                          "appUsers.placeholder.displayName"
                        )}
                        className={"tio-account_square_outlined"}
                        onChange={handleChangeSingleField({
                          fieldName: nameof(model.displayName),
                        })}
                        showCount
                        maxLength={255}
                      />
                    </FormItem>
                  </Col>
                  <Col lg={24} className="m-b--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.email)
                      )}
                      message={model.errors?.email}
                    >
                      <InputText
                        label={translate("appUsers.email")}
                        isRequired
                        type={0}
                        value={model.email}
                        placeHolder={translate("appUsers.placeholder.email")}
                        className={"tio-account_square_outlined"}
                        onChange={(value: string) => {
                          handleChangeSingleField({
                            fieldName: nameof(model.email),
                          })(value);
                          handleChangeSingleField({
                            fieldName: nameof(model.username),
                          })(value);
                        }}
                      />
                    </FormItem>
                  </Col>
                  {!model?.id && (
                    <Col lg={24} className="m-b--sm">
                      <FormItem
                        validateStatus={utilService.getValidateStatus(
                          model,
                          nameof(model.password)
                        )}
                        message={model.errors?.password}
                      >
                        <InputTextPassword
                          label={translate("appUsers.password")}
                          isRequired={true}
                          type={0}
                          value={model.password}
                          placeHolder={translate(
                            "appUsers.placeholder.password"
                          )}
                          className={"tio-account_square_outlined"}
                          onChange={handleChangeSingleField({
                            fieldName: nameof(model.password),
                          })}
                          typeInput={showPassword ? "text" : "password"}
                          suffix={
                            <button
                              type="button"
                              role="link"
                              className="btn btn-link"
                              onClick={toggleShowPassword}
                            >
                              {showPassword ? <ViewOff16 /> : <View16 />}
                            </button>
                          }
                        />
                      </FormItem>
                    </Col>
                  )}
                  <Col lg={24} className="m-b--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.phone)
                      )}
                      message={model.errors?.phone}
                    >
                      <InputText
                        label={translate("appUsers.phone")}
                        type={0}
                        value={model.phone}
                        placeHolder={translate("appUsers.placeholder.phone")}
                        className={"tio-account_square_outlined"}
                        onChange={handleChangeSingleField({
                          fieldName: nameof(model.phone),
                        })}
                      />
                    </FormItem>
                  </Col>
                  <Col lg={24} className="m-b--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.roleType)
                      )}
                      message={model.errors?.roleType}
                    >
                      <Select
                        label={translate("appUsers.roleType")}
                        type={0}
                        classFilter={RoleTypeFilter}
                        searchProperty={"displayName"}
                        placeHolder={translate("appUsers.placeholder.roleType")}
                        getList={appUserRepository.singleListRoleType}
                        onChange={handleChangeSelectField({
                          fieldName: nameof(model.roleType),
                        })}
                        value={model.roleType}
                        isRequired
                      />
                    </FormItem>
                  </Col>
                  <Col lg={24} className="m-b--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.organization)
                      )}
                      message={model.errors?.organization}
                    >
                      <Select
                        label={translate("appUsers.organization")}
                        type={0}
                        classFilter={OrganizationFilter}
                        searchProperty={"displayName"}
                        placeHolder={translate(
                          "appUsers.placeholder.organization"
                        )}
                        getList={appUserRepository.singleListOrganization}
                        onChange={handleChangeSelectField({
                          fieldName: nameof(model.organization),
                        })}
                        render={(organization: Organization) => {
                          return organization?.code && organization?.name
                            ? `${organization?.code} - ${organization?.name}`
                            : organization?.code
                            ? organization?.code
                            : null;
                        }}
                        value={model.organization}
                        isRequired
                      />
                    </FormItem>
                  </Col>
                  <Col lg={24} className="m-b--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.status)
                      )}
                      message={model.errors?.status}
                    >
                      <div>
                        <div className="label-status">
                          {translate("appUsers.status")}
                        </div>
                        <Switch
                          checked={model.statusId === 1 ? true : false}
                          onChange={(checked: boolean) => {
                            handleChangeSingleField({
                              fieldName: "statusId",
                            })(checked ? 1 : 0);
                            handleChangeSingleField({
                              fieldName: "status",
                            })(checked ? STATUS_ENUM[1] : STATUS_ENUM[0]);
                          }}
                          className="switch_status"
                        />
                      </div>
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Drawer>
  );
}

export default AppUserDetailDrawer;
