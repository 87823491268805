/* begin general import */
import _ from "lodash";
import { detailService } from "services/page-services/detail-service";
import { Major } from "models/Major";
import { majorRepository } from "repositories/major-repository";
import { fieldService } from "services/page-services/field-service";
import { MAJOR_ROUTE } from "config/route-consts";

/* end individual import */

export default function useMajorDetail() {
  // use hook
  const { model, dispatch } = detailService.useModel<Major>(Major);

  const { isDetail } = detailService.useGetIsDetail<Major>(
    majorRepository.get,
    dispatch
  );

  const {
    handleChangeDateField,
    handleChangeAllField,
    handleChangeSingleField,
    handleChangeSelectField,
  } = fieldService.useField(model, dispatch);

  const { handleSaveModel, handleGoMaster } =
    detailService.useActionsDetail<Major>(
      model,
      majorRepository.save,
      handleChangeAllField,
      MAJOR_ROUTE
    );

  return {
    model,
    isDetail,
    dispatch,
    handleChangeDateField,
    handleChangeSingleField,
    handleChangeSelectField,
    handleChangeAllField,
    handleGoMaster,
    handleSaveModel,
  };
}
