/* begin general import */

import {
  Add16,
  OverflowMenuHorizontal24,
  SettingsAdjust16,
  TrashCan16,
  Upload16,
  Websheet16,
} from "@carbon/icons-react";
import { Dropdown, Menu, PaginationProps, Spin, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import PageHeader from "components/PageHeader/PageHeader";
import { COURSE_SECTION_ROUTE } from "config/route-consts";
import { CourseSection, CourseSectionFilter } from "models/CourseSection";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionBarComponent,
  AdvanceIdFilterMaster,
  Button,
  OneLineText,
  Pagination,
  StandardTable,
  TagFilter,
} from "react3l-ui-library";
import { filterService } from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { masterService } from "services/page-services/master-service";
import { queryStringService } from "services/page-services/query-string-service";
import {
  getAntOrderType,
  tableService,
} from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import CourseSectionAdvanceFilter from "./CourseSectionAdvanceFilter";
import { IdFilter } from "react3l-advanced-filters";
import { renderMasterIndex } from "helpers/table";
import { importExportService } from "services/page-services/import-export-service";
import InputSearch from "components/InputSearch";
import { courseSectionRepository } from "repositories/course-section-repository";
import { Subject, SubjectFilter } from "models/Subject";
import { AppUser, AppUserFilter } from "models/AppUser";
import { Semester, SemesterFilter } from "models/Semester";
import { formatNumber } from "helpers/number";
import { CourseSectionShift } from "models/CourseSectionShift";
import { CourseSectionAppUserMapping } from "models/CourseSectionAppUserMapping";
import LayoutHeader from "components/LayoutHeader";
import LayoutCell from "components/LayoutCell";
import TruncateCell from "components/TruncateCell";
/* end individual import */

function CourseSectionMaster() {
  const [translate] = useTranslation();
  const [modelFilter, dispatch] = queryStringService.useQueryString(
    CourseSectionFilter,
    { skip: 0, take: 10 }
  );

  const {
    value: filter,
    handleChangeAllFilter,
    handleChangeSelectFilter,
    handleChangeInputSearch,
  } = filterService.useFilter(modelFilter, dispatch);

  const { list, count, loadingList, handleResetList } = listService.useList(
    courseSectionRepository.list,
    courseSectionRepository.count,
    filter,
    dispatch
  );

  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeAllFilter
  );

  const {
    handleAction,
    handleBulkAction,
    canBulkAction,
    selectedRowKeys,
    rowSelection,
    setSelectedRowKeys,
  } = listService.useRowSelection(
    courseSectionRepository.delete,
    courseSectionRepository.bulkDelete,
    null,
    null,
    null,
    handleResetList
  );

  const { handleDeleteItem, handleGoDetail, handleGoCreate } =
    masterService.useMasterAction(COURSE_SECTION_ROUTE, handleAction);

  const { handleExportTemplateList } = importExportService.useExport();

  const {
    loading: loadingImport,
    handleImportList,
    ref: importButtonRef,
    handleClick,
  } = importExportService.useImport(handleResetList);

  const [visible, setVisible] = React.useState<boolean>(false);

  const menuAction = React.useCallback(
    (id: number, courseSection: CourseSection) => (
      <Menu>
        <Menu.Item key="1">
          <div
            className="ant-action-menu text-center"
            onClick={handleGoDetail(id)}
          >
            {translate("general.actions.edit")}
          </div>
        </Menu.Item>
        {!courseSection?.used && (
          <Menu.Item key="2">
            <div
              className="ant-action-menu text-center"
              onClick={handleDeleteItem(courseSection)}
            >
              {translate("general.actions.delete")}
            </div>
          </Menu.Item>
        )}
      </Menu>
    ),
    [handleGoDetail, translate, handleDeleteItem]
  );

  const pagination: PaginationProps = tableService.usePagination(filter, count);

  const columns: ColumnProps<CourseSection>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
          />
        ),
        key: "index",
        width: 60,
        render(...params: [number, CourseSection, number]) {
          const index = renderMasterIndex<CourseSection>(pagination)(...params);
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={formatNumber(index)} />
            </LayoutCell>
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "code"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("courseSections.code")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].code),
        dataIndex: nameof(list[0].code),
        sorter: true,
        sortOrder: getAntOrderType<CourseSection, CourseSectionFilter>(
          filter,
          nameof(list[0].code)
        ),
        width: "12%",
        render(...params: [string, CourseSection, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "subject"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("courseSections.subject")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].subject),
        dataIndex: nameof(list[0].subject),
        sorter: true,
        sortOrder: getAntOrderType<CourseSection, CourseSectionFilter>(
          filter,
          nameof(list[0].subject)
        ),
        width: "15%",
        render(...params: [Subject, CourseSection, number]) {
          return <TruncateCell renderContent={params[0]?.name} />;
        },
      },
      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("courseSections.courseSectionAppUserMappings")}
            />
          );
        },
        key: nameof(list[0].courseSectionAppUserMappings),
        dataIndex: nameof(list[0].courseSectionAppUserMappings),
        width: "18%",
        render(
          ...params: [CourseSectionAppUserMapping[], CourseSection, number]
        ) {
          const listCourseSectionAppUserName = params[0]
            ? params[0]?.map(
                (courseSectionAppUser: CourseSectionAppUserMapping) =>
                  courseSectionAppUser?.appUser?.displayName
              )
            : [];
          return (
            <TruncateCell
              renderContent={listCourseSectionAppUserName.join(", ")}
            />
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "semester"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("courseSections.semester")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].semester),
        dataIndex: nameof(list[0].semester),
        sorter: true,
        sortOrder: getAntOrderType<CourseSection, CourseSectionFilter>(
          filter,
          nameof(list[0].semester)
        ),
        width: 150,
        render(...params: [Semester, CourseSection, number]) {
          return <TruncateCell renderContent={params[0]?.name} />;
        },
      },
      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("courseSections.courseSectionShifts")}
            />
          );
        },
        key: nameof(list[0].courseSectionShifts),
        dataIndex: nameof(list[0].courseSectionShifts),
        render(...params: [CourseSectionShift[], CourseSection, number]) {
          const listCourseSectionShiftName = params[0]
            ? params[0]?.map((courseSectionShift: CourseSectionShift) => {
                const classShift = courseSectionShift?.weekday
                  ? `${courseSectionShift?.weekday?.name} - ${courseSectionShift?.classShift?.name} - ${courseSectionShift?.room}`
                  : `${courseSectionShift?.classShift?.name} - ${courseSectionShift?.room}`;
                return classShift;
              })
            : [];
          return (
            <TruncateCell
              renderContent={listCourseSectionShiftName.join(", ")}
            />
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "numberStudent"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("courseSections.numberStudent")}
              sortedColumn={sortedColumn}
              isSorter
              position="right"
            />
          );
        },
        key: nameof(list[0].numberStudent),
        dataIndex: nameof(list[0].numberStudent),
        sorter: true,
        sortOrder: getAntOrderType<CourseSection, CourseSectionFilter>(
          filter,
          nameof(list[0].numberStudent)
        ),
        width: 150,
        render(...params: [number, CourseSection, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md" position="right">
              <OneLineText value={formatNumber(params[0])} />
            </LayoutCell>
          );
        },
      },

      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("general.actions.label")}
            />
          );
        },
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 80,
        align: "center",
        render(id: number, courseSection: CourseSection) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id, courseSection)}
                trigger={["click"]}
                placement="bottom"
                arrow
              >
                <OverflowMenuHorizontal24 />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [filter, list, menuAction, pagination, translate]
  );

  const handleCancel = React.useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <>
      <Spin spinning={loadingImport || loadingList}>
        <div className="page-content">
          <PageHeader
            title={translate("courseSections.master.subHeader")}
            breadcrumbItems={[
              translate("courseSections.master.header"),
              translate("courseSections.master.subHeader"),
            ]}
          />

          <div className="page page-master m-l--sm m-r--xxl m-b--xxs">
            <div className="page-master__title p-l--sm p-t--xs p-b--xs">
              {translate("courseSections.master.subHeader")}
            </div>
            <div className="page-master__content">
              <div className="page-master__tag-filter">
                <TagFilter
                  value={filter}
                  translate={translate}
                  keyTranslate={"courseSections"}
                  handleChangeFilter={handleChangeAllFilter}
                  onClear={() => {
                    return 0;
                  }}
                />
              </div>
              {(!selectedRowKeys || selectedRowKeys?.length === 0) && (
                <div className="page-master__filter-wrapper d-flex align-items-center justify-content-between">
                  <div className="page-master__filter d-flex align-items-center justify-content-start">
                    <div className="d-flex align-items-center">
                      <div className="">
                        <AdvanceIdFilterMaster
                          value={filter[nameof(list[0].semesterId)]["equal"]}
                          placeHolder={translate(
                            "courseSections.placeholder.semester"
                          )}
                          classFilter={SemesterFilter}
                          onChange={handleChangeSelectFilter({
                            fieldName: nameof(list[0].semester),
                            fieldType: "equal",
                            classFilter: IdFilter,
                          })}
                          getList={courseSectionRepository.filterListSemester}
                          title={translate("courseSections.semester")}
                        />
                      </div>
                      <div className="">
                        <AdvanceIdFilterMaster
                          value={filter[nameof(list[0].subjectId)]["equal"]}
                          placeHolder={translate(
                            "courseSections.placeholder.subject"
                          )}
                          classFilter={SubjectFilter}
                          onChange={handleChangeSelectFilter({
                            fieldName: nameof(list[0].subject),
                            fieldType: "equal",
                            classFilter: IdFilter,
                          })}
                          getList={courseSectionRepository.filterListSubject}
                          title={translate("courseSections.subject")}
                        />
                      </div>
                      <div className="">
                        <AdvanceIdFilterMaster
                          value={filter[nameof(list[0].appUserId)]["equal"]}
                          placeHolder={translate(
                            "courseSections.placeholder.appUser"
                          )}
                          classFilter={AppUserFilter}
                          onChange={(id, value) => {
                            value.name = value?.displayName;
                            handleChangeSelectFilter({
                              fieldName: nameof(list[0].appUser),
                              fieldType: "equal",
                              classFilter: IdFilter,
                            })(id, value);
                          }}
                          getList={courseSectionRepository.filterListAppUser}
                          title={translate("courseSections.appUser")}
                          render={(appUser: AppUser) => appUser?.displayName}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="page-master__filter-action-search d-flex align-items-center">
                    <Button
                      type="icon-only-ghost"
                      icon={<SettingsAdjust16 />}
                      onClick={() => setVisible(true)}
                      className="btn--xl"
                    />
                    <InputSearch
                      value={filter?.search}
                      classFilter={CourseSectionFilter}
                      placeHolder={translate("general.placeholder.search")}
                      onChange={handleChangeInputSearch}
                    />
                  </div>

                  <div className="page-master__actions  d-flex align-items-center justify-content-start">
                    <div className="page-master__filter-action d-flex align-items-center">
                      <input
                        ref={importButtonRef}
                        type="file"
                        style={{ display: "none" }}
                        id="master-import"
                        onClick={handleClick}
                        onChange={handleImportList(
                          courseSectionRepository.import
                        )}
                      />

                      <Tooltip title={translate("general.actions.import")}>
                        <div>
                          <Button
                            type="icon-only-ghost"
                            icon={<Upload16 />}
                            onClick={() => {
                              importButtonRef.current.click();
                            }}
                            className="btn--xl btn-grey ml-3"
                          />
                        </div>
                      </Tooltip>

                      <Tooltip
                        title={translate("general.actions.exportTemplate")}
                      >
                        <div>
                          <Button
                            type="icon-only-ghost"
                            icon={<Websheet16 />}
                            onClick={handleExportTemplateList(
                              courseSectionRepository.exportTemplate
                            )}
                            className="btn--xl btn-grey ml-3"
                          />
                        </div>
                      </Tooltip>

                      <Button
                        type="primary"
                        className="btn--lg"
                        icon={<Add16 />}
                        onClick={handleGoCreate}
                      >
                        {translate("general.actions.create")}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="page-master__content-table">
              <ActionBarComponent
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
              >
                <Button
                  icon={<TrashCan16 />}
                  type="ghost-primary"
                  className="btn--lg"
                  disabled={!canBulkAction}
                  onClick={() => handleBulkAction(selectedRowKeys)}
                >
                  {translate("general.actions.delete")}
                </Button>
              </ActionBarComponent>
              <StandardTable
                rowKey={nameof(list[0].id)}
                columns={columns}
                dataSource={list}
                isDragable={true}
                tableSize={"md"}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                spinning={loadingList}
                scroll={{ y: 500 }}
                bordered
              />

              <Pagination
                skip={filter?.skip}
                take={filter?.take}
                total={count}
                onChange={handlePagination}
                //canChangePageSize={false}
              />
            </div>
          </div>
        </div>
      </Spin>

      {visible && (
        <CourseSectionAdvanceFilter
          visible={visible}
          handleClose={handleCancel}
          visibleFooter={true}
          loading={false}
          size={"sm"}
          filter={filter}
          setVisible={setVisible}
          handleChangeAllFilter={handleChangeAllFilter}
        />
      )}
    </>
  );
}
export default CourseSectionMaster;
