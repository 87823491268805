import { Repository } from "react3l-common";
import { kebabCase } from "lodash";
import { httpConfig } from "config/http";
import { BASE_API_URL } from "config/consts";
import { Observable } from "rxjs";
import { AxiosResponse } from "axios";
import nameof from "ts-nameof.macro";
import { API_SUBJECT_PREFIX } from "config/api-consts";
import { Subject, SubjectFilter } from "models/Subject";
import { Status, StatusFilter } from "models/Status";
import { Major, MajorFilter } from "models/Major";

export type KeyType = string | number;

export class SubjectRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = new URL(API_SUBJECT_PREFIX, BASE_API_URL).href;
  }

  public count = (subjectFilter?: SubjectFilter): Observable<number> => {
    return this.http
      .post<number>(kebabCase(nameof(this.count)), subjectFilter)
      .pipe(Repository.responseDataMapper<number>());
  };

  public list = (subjectFilter?: SubjectFilter): Observable<Subject[]> => {
    return this.http
      .post<Subject[]>(kebabCase(nameof(this.list)), subjectFilter)
      .pipe(Repository.responseMapToList<Subject>(Subject));
  };

  public get = (id: number | string): Observable<Subject> => {
    return this.http
      .post<Subject>(kebabCase(nameof(this.get)), { id })
      .pipe(Repository.responseMapToModel<Subject>(Subject));
  };

  public create = (subject: Subject): Observable<Subject> => {
    return this.http
      .post<Subject>(kebabCase(nameof(this.create)), subject)
      .pipe(Repository.responseMapToModel<Subject>(Subject));
  };

  public update = (subject: Subject): Observable<Subject> => {
    return this.http
      .post<Subject>(kebabCase(nameof(this.update)), subject)
      .pipe(Repository.responseMapToModel<Subject>(Subject));
  };

  public delete = (subject: Subject): Observable<Subject> => {
    return this.http
      .post<Subject>(kebabCase(nameof(this.delete)), subject)
      .pipe(Repository.responseMapToModel<Subject>(Subject));
  };

  public bulkDelete = (idList: KeyType[]): Observable<void> => {
    return this.http
      .post(kebabCase(nameof(this.bulkDelete)), idList)
      .pipe(Repository.responseDataMapper());
  };

  public save = (subject: Subject): Observable<Subject> => {
    return subject.id ? this.update(subject) : this.create(subject);
  };

  public singleListStatus = (): Observable<Status[]> => {
    return this.http
      .post<Status[]>(
        kebabCase(nameof(this.singleListStatus)),
        new StatusFilter()
      )
      .pipe(Repository.responseMapToList<Status>(Status));
  };

  public filterListStatus = (): Observable<Status[]> => {
    return this.http
      .post<Status[]>(
        kebabCase(nameof(this.filterListStatus)),
        new StatusFilter()
      )
      .pipe(Repository.responseMapToList<Status>(Status));
  };

  public filterListMajor = (marjorFilter: MajorFilter): Observable<Major[]> => {
    return this.http
      .post<Major[]>(kebabCase(nameof(this.filterListMajor)), marjorFilter)
      .pipe(Repository.responseMapToList<Major>(Major));
  };
  public import = (
    file: File,
    name: string = nameof(file)
  ): Observable<void> => {
    const formData: FormData = new FormData();
    formData.append(name, file as Blob);
    return this.http
      .post<void>(kebabCase(nameof(this.import)), formData)
      .pipe(Repository.responseDataMapper<any>());
  };

  public export = (filter: any): Observable<AxiosResponse<any>> => {
    return this.http.post("export", filter, {
      responseType: "arraybuffer",
    });
  };

  public exportTemplate = (): Observable<AxiosResponse<any>> => {
    return this.http.post(
      "export-template",
      {},
      {
        responseType: "arraybuffer",
      }
    );
  };
}

export const subjectRepository = new SubjectRepository();
