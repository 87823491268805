import {
  STUDENT_DETAIL_ROUTE,
  STUDENT_MASTER_ROUTE,
} from "config/route-consts";
import { ProtectedRoute } from "pages/Authentication/ProtectedRoute";
import { Switch } from "react-router-dom";
import StudentMaster from "./StudentMaster/StudentMaster";
import StudentDetail from "./StudentDetail/StudentDetail";

function StudentView() {
  return (
    <Switch>
      <ProtectedRoute
        path={STUDENT_MASTER_ROUTE}
        key={STUDENT_MASTER_ROUTE}
        component={StudentMaster}
        auth={true}
      />
      <ProtectedRoute
        path={STUDENT_DETAIL_ROUTE}
        key={STUDENT_DETAIL_ROUTE}
        component={StudentDetail}
        auth={true}
      />
    </Switch>
  );
}

export { StudentMaster };
export default StudentView;
