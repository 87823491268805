/* begin general import */
import { Col, Row, Spin } from "antd";
import nameof from "ts-nameof.macro";
import { utilService } from "services/common-services/util-service";
import FormItem from "react3l-ui-library/build/components/FormItem";
import { Button, DatePicker, InputNumber, Select } from "react3l-ui-library";
import InputText from "react3l-ui-library/build/components/Input/InputText";
import _ from "lodash";
import "./RollCallDetail.scss";
import { useTranslation } from "react-i18next";
import { SubjectFilter } from "models/Subject";
import {
  detailService,
  ModelActionEnum,
} from "services/page-services/detail-service";
import { RollCall } from "models/RollCall";
import { fieldService } from "services/page-services/field-service";
import { SemesterFilter } from "models/Semester";
import React from "react";
import { rollCallRepository } from "repositories/roll-call-repository";
import { CourseSectionAppUserMapping } from "models/CourseSectionAppUserMapping";
import { RollCallTable } from "./RollCallDetailHook/RollCallTable";
import { queryStringService } from "services/page-services/query-string-service";
import { webService } from "services/common-services/web-service";
import { Close16 } from "@carbon/icons-react";
import { ROLL_CALL_ROUTE } from "config/route-consts";
import { STANDARD_DATE_FORMAT_INVERSE } from "config/consts";

/* end individual import */

function RollCallDetail() {
  const [translate] = useTranslation();

  // use hook
  const { model, dispatch } = detailService.useModel<RollCall>(RollCall);

  const { courseSectionId }: any =
    queryStringService.useGetQueryString("courseSectionId");
  const isDetail = React.useMemo(
    () => courseSectionId !== null,
    [courseSectionId]
  );
  const [subscription] = webService.useSubscription();

  React.useEffect(() => {
    if (isDetail) {
      subscription.add(
        rollCallRepository.getCourseSection(courseSectionId).subscribe({
          next: (res) => dispatch({ type: ModelActionEnum.SET, payload: res }),
          error: (_err) => {},
        })
      );
    }
  }, [courseSectionId, dispatch, isDetail, subscription]);

  const {
    handleChangeDateField,
    handleChangeSingleField,
    handleChangeSelectField,
    handleChangeAllField,
  } = fieldService.useField(model, dispatch);

  const { handleGoMaster } = detailService.useActionsDetail<RollCall>(
    model,
    rollCallRepository.save,
    handleChangeAllField,
    ROLL_CALL_ROUTE
  );

  const listCourseSectionAppUserName = React.useMemo(() => {
    return model?.courseSectionAppUserMappings
      ? model?.courseSectionAppUserMappings?.map(
          (courseSectionAppUser: CourseSectionAppUserMapping) =>
            courseSectionAppUser?.appUser?.displayName
        )
      : [];
  }, [model?.courseSectionAppUserMappings]);

  return (
    <Spin spinning={false}>
      <div className="page-content page-content-dxg" id="page-content-dxg-id">
        <div className="info-component m-l--sm m-r--xxl mb-3">
          <span>{translate("rollCalls.master.header")}</span>
          <span> &gt; </span>
          <span className="info-primary">
            {isDetail
              ? translate("rollCalls.detail.generalTitle")
              : translate("general.actions.create")}
          </span>
        </div>
        <div className="page page-detail">
          <div className="d-flex">
            <h5 className="font-bold page-title m-l--sm m-t--sm">
              {isDetail
                ? `${model.code} - ${model.subject?.name}`
                : translate("rollCalls.detail.titleCreate")}
            </h5>
          </div>
          <div className="page-detail__content">
            <div className="page-detail__title p-b--lg">
              {translate("rollCalls.detail.generalTitle")}
            </div>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {/* Mã ngành học*/}
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.code)
                  )}
                  message={model.errors?.code}
                >
                  <InputText
                    label={translate("rollCalls.code")}
                    type={0}
                    value={model.code}
                    placeHolder={translate("rollCalls.placeholder.code")}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.code),
                    })}
                    showCount
                    maxLength={20}
                    disabled
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.subject)
                  )}
                  message={model.errors?.subject}
                >
                  <Select
                    label={translate("rollCalls.subject")}
                    type={0}
                    classFilter={SubjectFilter}
                    placeHolder={translate("rollCalls.placeholder.subject")}
                    getList={rollCallRepository.singleListSubject}
                    onChange={handleChangeSelectField({
                      fieldName: nameof(model.subject),
                    })}
                    value={model.subject}
                    disabled
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.courseSectionAppUserMappings)
                  )}
                  message={model.errors?.courseSectionAppUserMappings}
                >
                  <InputText
                    label={translate("rollCalls.courseSectionAppUserMappings")}
                    type={0}
                    value={listCourseSectionAppUserName.join(", ")}
                    placeHolder={translate(
                      "rollCalls.placeholder.courseSectionAppUserMappings"
                    )}
                    className={"tio-account_square_outlined"}
                    disabled
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.semester)
                  )}
                  message={model.errors?.semester}
                >
                  <Select
                    label={translate("rollCalls.semester")}
                    type={0}
                    classFilter={SemesterFilter}
                    placeHolder={translate("rollCalls.placeholder.semester")}
                    getList={rollCallRepository.singleListSemester}
                    onChange={(id, T) => {
                      handleChangeSelectField({
                        fieldName: nameof(model.semester),
                      })(id, T);
                      if (model?.isStartSameSemester) {
                        handleChangeDateField({
                          fieldName: nameof(model.startedAt),
                        })(T?.startedAt);
                        handleChangeDateField({
                          fieldName: nameof(model.endedAt),
                        })(T?.endedAt);
                      }
                    }}
                    value={model.semester}
                    disabled
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.numberOfCreditHours)
                  )}
                  message={model.errors?.numberOfCreditHours}
                >
                  <InputNumber
                    label={translate("rollCalls.numberOfCreditHours")}
                    type={0}
                    value={model.numberOfCreditHours}
                    placeHolder={translate(
                      "rollCalls.placeholder.numberOfCreditHours"
                    )}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.numberOfCreditHours),
                    })}
                    disabled
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.startedAt)
                  )}
                  message={model.errors?.startedAt}
                >
                  <DatePicker
                    label={translate("rollCalls.startedAt")}
                    value={model.startedAt}
                    type={0}
                    placeholder={translate("rollCalls.placeholder.startedAt")}
                    onChange={handleChangeDateField({
                      fieldName: nameof(model.startedAt),
                    })}
                    disabled
                    dateFormat={[STANDARD_DATE_FORMAT_INVERSE]}
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.endedAt)
                  )}
                  message={model.errors?.endedAt}
                >
                  <DatePicker
                    label={translate("rollCalls.endedAt")}
                    value={model.endedAt}
                    type={0}
                    placeholder={translate("rollCalls.placeholder.endedAt")}
                    onChange={handleChangeDateField({
                      fieldName: nameof(model.endedAt),
                    })}
                    disabled
                    dateFormat={[STANDARD_DATE_FORMAT_INVERSE]}
                  />
                </FormItem>
              </Col>
            </Row>

            <div className="p-t--lg p-b--space-ultra-wire">
              {courseSectionId && (
                <RollCallTable courseSectionId={courseSectionId} />
              )}
            </div>
          </div>
          <footer className="app-footer">
            <div className="app-footer__full d-flex justify-content-end align-items-center">
              <div className="app-footer__actions d-flex justify-content-end">
                <Button
                  type="secondary"
                  className="btn--lg btn-red"
                  icon={<Close16 />}
                  onClick={handleGoMaster}
                >
                  {translate("general.actions.close")}
                </Button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Spin>
  );
}

export default RollCallDetail;
