/* begin general import */
import { Download16 } from "@carbon/icons-react";
import { notification, Spin } from "antd";
import { ColumnProps } from "antd/lib/table";
import PageHeader from "components/PageHeader/PageHeader";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AdvanceIdFilterMaster,
  Button,
  OneLineText,
  TagFilter,
} from "react3l-ui-library";
import { filterService } from "services/page-services/filter-service";
import { queryStringService } from "services/page-services/query-string-service";
import {
  getAntOrderType,
  tableService,
} from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import { IdFilter } from "react3l-advanced-filters";
import { renderMasterIndex } from "helpers/table";
import { reportRepository } from "repositories/report-repository";
import { Subject, SubjectFilter } from "models/Subject";
import { Semester, SemesterFilter } from "models/Semester";
import { ReportFilter, Report } from "models/Report";
import { CourseSection, CourseSectionFilter } from "models/CourseSection";
import StandardTable from "components/StandardTable";
import { webService } from "services/common-services/web-service";
import { finalize } from "rxjs/operators";
import { Moment } from "moment";
import { formatDate } from "helpers/date-time";
import { AppUser, AppUserFilter } from "models/AppUser";
import { AxiosError, AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { Observable } from "rxjs";
import saveAs from "file-saver";
import AdvanceDateRangeFilterMaster from "components/AdvanceDateRangeFilter";
import LayoutHeader from "components/LayoutHeader";
import LayoutCell from "components/LayoutCell";
import { formatNumber } from "helpers/number";
/* end individual import */

function ReportNotRollCall() {
  const [translate] = useTranslation();
  const [modelFilter, dispatchFilter] = queryStringService.useQueryString(
    ReportFilter,
    {
      skip: 0,
      take: 10,
    }
  );

  const {
    value: filter,
    handleChangeAllFilter,
    handleChangeSelectFilter,
    handleChangeDateFilter,
  } = filterService.useFilter(modelFilter, dispatchFilter);

  const [list, setList] = React.useState<Report[]>([]);

  const [loadingList, setLoadingList] = React.useState<boolean>(false);
  const [loadingExport, setLoadingExport] = React.useState<boolean>(false);

  const [subscription] = webService.useSubscription();

  const handleLoadList = React.useCallback(
    (filterParam?: ReportFilter) => {
      const filterValue = filterParam ? { ...filterParam } : { ...filter };
      subscription.add(
        reportRepository
          .listReportNotRollCall(filterValue)
          .pipe(finalize(() => setLoadingList(false)))
          .subscribe({
            next: (results: Report[]) => {
              if (results && results.length > 0) {
                results.forEach((current: any) => {
                  if (typeof current.key === "undefined") {
                    current.key = uuidv4();
                  }
                });
              }
              setList(results);
            },
            error: (error: AxiosError<any>) => {
              setList([]);
              if (error.response && error.response.status === 400) {
                notification.error({
                  message: error.response?.data,
                  placement: "bottomRight",
                });
              }
            },
          })
      );
    },
    [filter, subscription]
  );

  /** handle action when export succesfully **/
  const handleExportSuccess = (response: AxiosResponse<any>) => {
    const fileName = response.headers["content-disposition"]
      .split(";")
      .find((n: any) => n.includes("filename="))
      .replace("filename=", "")
      .trim(); // define fileName for saver
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/octet-stream",
      })
    ); // defince urlObject
    setLoadingExport(false);
    saveAs(url, fileName); // save file
  };

  const handleListExport = React.useCallback(
    (
      filter: ReportFilter,
      onExport: (filter: ReportFilter) => Observable<AxiosResponse<any>>
    ) => {
      return () => {
        if (typeof onExport === "function") {
          setLoadingExport(true);
          subscription.add(
            onExport(filter)
              .pipe(finalize(() => setLoadingExport(false)))
              .subscribe({
                next: (response: AxiosResponse<any>) => {
                  handleExportSuccess(response); // onSuccess
                },
                error: (error: AxiosError<any>) => {
                  setList([]);
                  if (error.response && error.response.status === 400) {
                    if (!filter?.semesterId?.equal) {
                      notification.error({
                        message: "Vui lòng chọn học kì cần xem báo cáo.",
                        placement: "bottomRight",
                      });
                    }
                  }
                },
              })
          );
        }
      };
    },
    [subscription]
  );

  React.useEffect(() => {
    if (filter) {
      setLoadingList(true);
      handleLoadList();
    }
  }, [filter, handleLoadList]);

  const { handleTableChange } = tableService.useTable(
    filter,
    handleChangeAllFilter
  );

  const columns: ColumnProps<Report>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
            position="center"
          />
        ),
        key: "index",
        width: 60,
        render(...params: [CourseSection, Report, number]) {
          const index = renderMasterIndex<Report>()(...params);
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={formatNumber(index)} />
            </LayoutCell>
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "courseSection"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("reports.courseSections.code")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].courseSection),
        dataIndex: nameof(list[0].courseSection),
        sorter: true,
        sortOrder: getAntOrderType<Report, ReportFilter>(
          filter,
          nameof(list[0].courseSection)
        ),
        render(...params: [CourseSection, Report, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]?.code} countCharacters={35} />
            </LayoutCell>
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "subject"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("reports.subject")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].subject),
        dataIndex: nameof(list[0].subject),
        sorter: true,
        sortOrder: getAntOrderType<Report, ReportFilter>(
          filter,
          nameof(list[0].subject)
        ),
        render(...params: [Subject, Report, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]?.name} countCharacters={35} />
            </LayoutCell>
          );
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "semester"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("reports.semester")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].semester),
        dataIndex: nameof(list[0].semester),
        sorter: true,
        sortOrder: getAntOrderType<Report, ReportFilter>(
          filter,
          nameof(list[0].semester)
        ),
        render(...params: [Semester, Report, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]?.name} countCharacters={35} />
            </LayoutCell>
          );
        },
      },
      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("reports.appUserNames")}
            />
          );
        },
        key: nameof(list[0].appUserNames),
        dataIndex: nameof(list[0].appUserNames),
        render(...params: [string, Report, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]} countCharacters={35} />
            </LayoutCell>
          );
        },
      },
      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("reports.lessonDate")}
              position="center"
            />
          );
        },
        key: nameof(list[0].lessonDate),
        dataIndex: nameof(list[0].lessonDate),
        width: 180,
        render(...params: [Moment, Report, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={params[0] ? formatDate(params[0]) : ""} />
            </LayoutCell>
          );
        },
      },
    ],
    [translate, list, filter]
  );

  return (
    <>
      <Spin spinning={loadingExport || loadingList}>
        <div className="page-content">
          <PageHeader
            title={translate("reports.reportNotRollCalls.master.header")}
            breadcrumbItems={[
              translate("menu.title.report"),
              translate("reports.reportNotRollCalls.master.header"),
            ]}
          />

          <div className="page page-master m-l--sm m-r--xxl m-b--xxs">
            <div className="page-master__title p-l--sm p-t--xs p-b--xs">
              {translate("reports.reportNotRollCalls.master.header")}
            </div>
            <div className="page-master__content">
              <div className="page-master__tag-filter">
                <TagFilter
                  value={filter}
                  translate={translate}
                  keyTranslate={"reports"}
                  handleChangeFilter={handleChangeAllFilter}
                  onClear={() => {
                    return 0;
                  }}
                />
              </div>
              {
                <div className="page-master__filter-wrapper d-flex align-items-center justify-content-between">
                  <div className="page-master__filter d-flex align-items-center justify-content-start">
                    <div className="d-flex align-items-center">
                      <div className="">
                        <AdvanceIdFilterMaster
                          value={filter["courseSectionId"]["equal"]}
                          placeHolder={translate(
                            "reports.placeholder.courseSection"
                          )}
                          classFilter={CourseSectionFilter}
                          onChange={handleChangeSelectFilter({
                            fieldName: "courseSection",
                            fieldType: "equal",
                            classFilter: IdFilter,
                          })}
                          getList={reportRepository.filterListCourseSection}
                          title={translate("reports.courseSection")}
                        />
                      </div>
                      <div className="">
                        <AdvanceIdFilterMaster
                          value={filter["subjectId"]["equal"]}
                          placeHolder={translate("reports.placeholder.subject")}
                          classFilter={SubjectFilter}
                          onChange={handleChangeSelectFilter({
                            fieldName: "subject",
                            fieldType: "equal",
                            classFilter: IdFilter,
                          })}
                          getList={reportRepository.filterListSubject}
                          title={translate("reports.subject")}
                        />
                      </div>
                      <div className="">
                        <AdvanceIdFilterMaster
                          value={filter["semesterId"]["equal"]}
                          placeHolder={translate(
                            "reports.placeholder.semester"
                          )}
                          classFilter={SemesterFilter}
                          onChange={handleChangeSelectFilter({
                            fieldName: "semester",
                            fieldType: "equal",
                            classFilter: IdFilter,
                          })}
                          getList={reportRepository.filterListSemester}
                          title={translate("reports.semester")}
                        />
                      </div>

                      <div className="">
                        <AdvanceIdFilterMaster
                          value={filter[nameof(list[0].appUserId)]["equal"]}
                          placeHolder={translate("reports.placeholder.appUser")}
                          classFilter={AppUserFilter}
                          onChange={(id, value) => {
                            value.name = value?.displayName;
                            handleChangeSelectFilter({
                              fieldName: nameof(list[0].appUser),
                              fieldType: "equal",
                              classFilter: IdFilter,
                            })(id, value);
                          }}
                          getList={reportRepository.filterListAppUser}
                          title={translate("reports.appUser")}
                          render={(appUser: AppUser) => appUser?.displayName}
                        />
                      </div>
                      <div className="">
                        <AdvanceDateRangeFilterMaster
                          onChange={(value?: [any, any]) => {
                            const internalValue =
                              !value[0] && !value[1] ? undefined : value;
                            handleChangeDateFilter({
                              fieldName: "date",
                              fieldType: ["greaterEqual", "lessEqual"],
                            })(internalValue);
                          }}
                          value={[
                            filter?.date?.greaterEqual as any,
                            filter?.date?.lessEqual as any,
                          ]}
                          placeholder={[
                            translate("general.filter.startDate"),
                            translate("general.filter.endDate"),
                          ]}
                          label={translate("reports.date")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="page-master__actions  d-flex align-items-center justify-content-start">
                    <div className="page-master__filter-action d-flex align-items-center">
                      <Button
                        type="primary"
                        icon={<Download16 />}
                        onClick={handleListExport(
                          filter,
                          reportRepository.exportReportNotRollCall
                        )}
                        className="btn--lg"
                      >
                        {translate("reports.exportReportNotRollCall")}
                      </Button>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="page-master__content-table">
              <StandardTable
                rowKey={nameof(list[0].key)}
                columns={columns}
                dataSource={list}
                isDragable={true}
                tableSize={"md"}
                onChange={handleTableChange}
                spinning={loadingList}
                bordered
                scroll={{ y: 500 }}
              />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
}
export default ReportNotRollCall;
