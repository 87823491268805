import { Model } from "react3l-common";
import { Field, MomentField } from "react3l-decorators";
import type { Moment } from "moment";

export class Semester extends Model {
  @Field(Number)
  public id?: number;

  @Field(String)
  public code?: string;

  @Field(String)
  public name?: string;

  @MomentField()
  public startedAt?: Moment;

  @MomentField()
  public endedAt?: Moment;
}
